import React from "react";
import Input from "antd/lib/input";
import Form from "antd/lib/form";

import { symptomsListGql, medicalconditionsWizard, alergysWizardMD } from "../gqlWizard";
import InputWordCloud from "components/InputWordCloud";

const Steps2 = (props) => {
  const { form, onFinishForm } = props;
  const { TextArea } = Input;

  return (
    <>
      <div className='flex justify-center w-full mt-10'>
        <h3 style={{ color: "#707070" }} className='text-xl'>
          Detalles de la consulta
        </h3>
      </div>
      <div
        style={{ minHeight: "198px", alignItems: "baseline" }}
        className='flex justify-center w-full mt-3 mb-12'
      >
        <Form
          form={form}
          layout='horizontal'
          className='grid grid-cols-12 gap-2 w-full'
          validateTrigger='onBlur'
          name='basic'
          onFinish={(valueForm) => onFinishForm(valueForm)}
        >
          <div className='col-span-12 lg:col-span-6 flex flex-col justify-center'>
            <label className='mb-2'>Síntomas del paciente</label>
            <Form.Item
              label={null}
              name='sintomas'
              rules={[{ required: true, message: "Por favor ingrese los síntomas!" }]}
            >
              <InputWordCloud
                gql={symptomsListGql()}
                path='symptoms.docs'
                placeholder='Buscar síntomas'
                className='w-full mt-2'
              />
            </Form.Item>
          </div>
          <div className='col-span-12 lg:col-span-6 flex flex-col'>
            <label className='mb-2'>Razón de la consulta </label>
            <Form.Item
              label={null}
              name='razónDeLaConsulta'
              rules={[{ required: true, message: "Por favor ingrese el motivo de la consulta!" }]}
            >
              <TextArea placeholder='Escriba la razón de la consulta ' className='mt-2' rows={1} />
            </Form.Item>
          </div>

          <div className='col-span-12 lg:col-span-6 flex flex-col'>
            <label className='mb-2'>Condiciones médicas</label>
            <Form.Item label={null} name='medicalconditions'>
              <InputWordCloud
                gql={medicalconditionsWizard()}
                path='medicalconditions.docs'
                placeholder='Buscar condiciones médicas'
                className='w-full mt-2'
              />
            </Form.Item>
          </div>

          <div className='col-span-12 lg:col-span-6 flex flex-col'>
            <label className='mb-2'>Alergias a medicamentos</label>
            <Form.Item label={null} name='drugAllergies'>
              <InputWordCloud
                gql={alergysWizardMD()}
                path='drugs.docs'
                placeholder='Buscar alergias'
                className='w-full mt-2'
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Steps2;
