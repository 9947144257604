import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
// antd
import Card from "antd/lib/card";
import Spin from "antd/lib/spin";
import Form from "antd/lib/form";
// context
import Context from "../../Context";
import { GlobalContext } from "views/App";
// components
import FormInfo from "components/Person/Profile/FormInfo";
import { typeBLOOD } from "components/Person/Profile/inputs/info";
// gql
import { getMeInfo, getInfoBeneficiary, muteMeInfo, muteInfoBeneficiary } from "../../gql";
// helpers
import { find, get, some, upperCase, differenceWith, toPairs, fromPairs, isEqual } from "lodash";
// style
import style from "../../style.module.scss";
import UpdateInfoButton from "components/UpdateInfoButton";

function Info() {
  const [form] = Form.useForm();
  const { state } = useContext(GlobalContext);
  const { person } = useContext(Context);
  const [initialValues, setInitialValues] = useState({});
  const [newVariables, setNewVariables] = useState(null);
  const isMain = state.user.id === person;

  const gql = {
    path: isMain ? "patientById" : "beneficiaryById",
    query: isMain ? getMeInfo : getInfoBeneficiary,
    mutation: isMain ? muteMeInfo : muteInfoBeneficiary,
  };

  const stateQuery = useQuery(gql.query, {
    variables: { id: person },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const { data, error, loading } = stateQuery;
    if (!loading) {
      if (error) {
        console.log(error);
      }
      if (data) {
        const info = get(data, gql.path, false);
        if (info) {
          const { sex, typeOfBlood, ...fields } = info;
          setInitialValues({
            ...fields,
            sex: sex && sex !== "" ? upperCase(sex) : null,
            typeOfBlood: getCorrectValueForTypeOfBlood(typeOfBlood),
          });
        }
      }
    }
  }, [stateQuery, gql.path]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  const getCorrectValueForTypeOfBlood = (val) => {
    if (!val) {
      return null;
    }
    const findValue = find(typeBLOOD, function (o) {
      return o.label === val;
    });
    return get(findValue, ["value"], null);
  };

  const onFinish = (variables) => {
    const isDiff = some(variables, (val, key) => initialValues[key] !== val);
    const diffValues = fromPairs(
      differenceWith(toPairs(variables), toPairs(initialValues), isEqual)
    );
    if (isDiff) {
      if (diffValues.phoneNumber) diffValues.phoneNumber = String(diffValues.phoneNumber);
      setNewVariables(diffValues);
    }
  };

  const successfullMutation = (success) => {
    success && setInitialValues({ ...initialValues, ...newVariables });
  };

  const propsButton = {
    mutation: gql.mutation,
    variables: newVariables,
    isMain,
    person,
    successfullMutation,
  };
  const props = {
    Card: {
      title: "Mi Tarjeta Personal",
      extra: [stateQuery.loading ? <Spin /> : <UpdateInfoButton {...propsButton} />],
      className: style.Info,
    },
    Form: {
      isMain,
      form,
      onFinish,
      initialValues,
      onBlur: form.submit,
    },
  };

  return (
    <Card {...props.Card}>
      <FormInfo {...props.Form} />
    </Card>
  );
}

export default React.memo(Info);
