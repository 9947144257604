import React from "react";

import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

export default {
  Upload: {
    accept: "image/png,image/jpeg",
    fileList: [],
  },
  Btn: {
    size: "small",
    type: "primary",
    icon: <FA icon={faPen} style={{ fontSize: ".8rem" }} />,
    shape: "circle",
    style: {
      right: 0,
      bottom: 0,
      border: 0,
      position: "absolute",
      transform: "translateX(25%)",
      backgroundColor: "#8BC53F",
    },
  },
};
