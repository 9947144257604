import React, { useContext, useEffect, useState } from "react";
import OT from "@opentok/client";
import "./opentok.css";
import {
  faVideoSlash,
  faVideo,
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getVonageSessionToken from "utils.js/getVonageSessionToken";
import { GlobalContext } from "views/App";
const { REACT_APP_VONAGE_API_KEY: apiKey } = process.env;

export default function OpenTok({
  infoAppointment,
  terminarSessionLlamada,
  setSpinCall,
  infoForChat,
  setCountFullCallUsers,
  disconnectCall,
}) {
  const [publisher, setPublisher] = useState(null);
  const [audioMute, setAudioMute] = useState(true);
  const [videoMute, setVideoMute] = useState(false);
  const [subscriberVideoMute, setSubscriberVideoMute] = useState(true);
  const [subScriberaudioMute, setSubscriberAudioMute] = useState(true);
  const [token, setToken] = useState(null);

  const vonageSessionId = infoAppointment?.vonageSessionId;
  const opentok = OT.initSession(apiKey, vonageSessionId);

  const { state } = useContext(GlobalContext);

  useEffect(() => {}, []);

  const publisherOptions = {
    insertMode: "append",
    width: "100%",
    height: "100%",
    publishAudio: true,
    publisheVideo: true,
    name: state?.user?.fullName,
    fitMode: "contain",
    style: { buttonDisplayMode: "off" },
  };

  const subscriberOptions = {
    insertMode: "append",
    width: "100%",
    height: "100%",
    name: infoForChat?.name,
    fitMode: "contain",
    style: { buttonDisplayMode: "off" },
  };

  useEffect(() => {
    async function getToken() {
      const token = await getVonageSessionToken(vonageSessionId);
      setToken(token);
    }
    if (vonageSessionId) {
      getToken();
    }
  }, [vonageSessionId]);

  useEffect(() => {
    const _publisher = OT.initPublisher("publisher", publisherOptions, (err) => {
      console.error(err);
    });
    setPublisher(_publisher);
    const DISCONNECT_CALL_TIME_OUT = setTimeout(() => {
      terminarSessionLlamada();
      opentok.off();
      opentok.disconnect();
    }, 30000);
    // Patient Joined the call
    opentok.on("streamCreated", ({ stream }) => {
      opentok.subscribe(stream, "subscriber", subscriberOptions, (error) => {
        if (error) {
          console.error("There was an error publishing: ", error.name, error.message);
        }
        console.log("USER JOINED CLEAR CALL DISCONECT TIME OUT");
        console.log("Stream created", stream);
        clearTimeout(DISCONNECT_CALL_TIME_OUT);
        setCountFullCallUsers((c) => c + 1);
        setSpinCall(false);
      });
    });

    opentok.on("streamDestroyed", (event) => {
      console.log("Stream destroyed!", event);
      terminarSessionLlamada();
      opentok.disconnect();
      disconnectCall();
    });
    opentok.on("streamPropertyChanged", (event) => {
      console.log("streamPropertyChanged!", event);
      if (state?.user?.fullName !== event.stream.name) {
        if (event.changedProperty === "hasAudio") {
          setSubscriberAudioMute(event.newValue);
        }
        if (event.changedProperty === "hasVideo") {
          setSubscriberVideoMute(event.newValue);
        }
      }
    });
    opentok.on("sessionConnected", (event) => {
      console.log("Session Created", event);
    });
    opentok.on("sessionDisconnected", (event) => {
      console.log("session Disconnected", event);
      terminarSessionLlamada();
    });
    opentok.on("connectionCreated", (event) => {
      console.log("connectionCreated", event);
    });
    opentok.on("connectionDestroyed", (event) => {
      console.log("connectionDestroyed", event);
      terminarSessionLlamada();
    });
    opentok.on("otrnError", (event) => {
      console.log("otrnError", event);
    });
    opentok.on("error", (event) => {
      console.log("error", event);
    });

    return () => {
      opentok.off();
      opentok.disconnect();
      clearTimeout(DISCONNECT_CALL_TIME_OUT);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (publisher) {
        publisher.destroy();
      }
    };
  }, [publisher]);

  useEffect(() => {
    if (publisher && token) {
      opentok.connect(token, (err) => {
        if (err) {
          throw new Error(err);
        }
        opentok.publish(publisher);
      });
    }
  }, [publisher, token]);

  function handleAudio() {
    console.log("switching audio");
    setAudioMute(!audioMute);
    publisher.publishAudio(!publisher.getAudioSource().enabled);
  }
  function handleVideo() {
    console.log("switching video");
    setVideoMute(!videoMute);
    publisher.publishVideo(!publisher.getVideoSource().track?.enabled);
  }
  function handleDisconnect() {
    publisher.destroy();
  }

  return (
    <>
      <div id='publisher' />
      <div id='subscriber' />
      <div id='subscriber-utility'>
        {!subScriberaudioMute && <h1>{infoForChat?.name} muted audio</h1>}
        {!subscriberVideoMute && <h1>{infoForChat?.name} muted video</h1>}
      </div>
      <div id='video_actions_container'>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <div className='video_actions'>
            <div className='utility-icon' onClick={handleVideo}>
              <FontAwesomeIcon
                className='mx-2'
                size='lg'
                icon={videoMute ? faVideoSlash : faVideo}
              />
            </div>
            <div className='utility-icon' onClick={handleAudio}>
              <FontAwesomeIcon
                className='mx-2'
                size='lg'
                icon={audioMute ? faMicrophone : faMicrophoneSlash}
              />
            </div>
            <div className='utility-icon' onClick={handleDisconnect}>
              <FontAwesomeIcon className='mx-2' size='lg' icon={faPhone} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
