import React from "react";
// antd
import Spin from "antd/lib/spin";
import Select from "antd/lib/select";
// components
import Tags from "./Tags";
// helpers
import filter from "lodash/filter";

export default function Selector(_) {
  const {
    values,
    loading,
    options,
    onRemove,
    emptyText = "",
    disabled,
    showEmpty = true,
    ...attr
  } = _;

  const notFoundContent = loading ? <Spin size='small' /> : null;

  const showOpts = values ? filter(options, ({ label }) => !values.includes(label)) : options;

  const props = {
    Tags: {
      values,
      onRemove,
      disabled,
      emptyText,
      showEmpty,
    },
    Select: {
      loading,
      disabled,
      notFoundContent,
      value: [],
      options: showOpts,
      allowClear: true,
      showSearch: true,
      filterOption: false,
      ...attr,
    },
  };

  return (
    <>
      <Select {...props.Select} />
      <Tags {...props.Tags} />
    </>
  );
}
