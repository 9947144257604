import React, { useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
import Space from "antd/lib/space";
import notify from "antd/lib/notification";
// components
import FormInfo from "../Profile/FormInfo";
import UploadPicture from "./UploadPicture";
import FormCondition from "../Profile/FormCondition";
// helpers
import get from "lodash/get";
// gql
import { createBeneficiary } from "views/Patient/gql";
// props Statics
import propsStatic from "./propsStatic";

function Dialog({ gql, onSuccess, onError, ...attr }) {
  const [form] = Form.useForm();
  const [applyMute, { loading, ...states }] = useMutation(gql, {
    refetchQueries: ["getMeBeneficiaries"],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (attr.visible) form.resetFields();
  }, [attr.visible, form]);

  const afterClose = () => {
    if (attr.afterClose) attr.afterClose({ ...states, loading });
  };

  const onFinish = (variables) => {
    applyMute({ variables })
      .then(() => {
        if (onSuccess) onSuccess();
        notify.success({ message: "Beneficiario agregado" });
        attr.onCancel();
      })
      .catch((error) => {
        if (onError) onError();

        if (error.graphQLErrors) {
          const description = get(error.graphQLErrors, [0, "message"], error.graphQLErrors);
          notify.error({
            message: "Error",
            description,
          });
        }
      });
  };

  const props = {
    Modal: {
      ...propsStatic.Modal,
      onOk: form.submit,
      cancelButtonProps: {
        ...propsStatic.Modal.cancelButtonProps,
        disabled: loading,
      },
      confirmLoading: loading,
      ...attr,
      afterClose,
    },
    Form: {
      form,
      onFinish,
      name: "person",
      initialValues: {},
    },
  };

  return (
    <Modal {...props.Modal}>
      <Space {...propsStatic.Space}>
        <FormInfo {...props.Form} />
        <Form {...props.Form}>
          <Form.Item name='picture'>
            <UploadPicture />
          </Form.Item>
        </Form>
      </Space>
      <FormCondition {...props.Form} />
    </Modal>
  );
}

Dialog.defaultProps = {
  gql: createBeneficiary,
  onError: null,
  onSuccess: null,
};

export default Dialog;
