import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
// antd
import Tabs from "antd/lib/tabs";

// components
import Home from "./Home";
import Appointment from "./Appointments";
// context
import Context from "views/Patient/Context";
// helpers
import get from "lodash/get";

export default function TabsContainer() {
  const { appointment, setAppointment } = useContext(Context);
  const [activeKey, setActiveKey] = useState("1");
  const history = useHistory();

  useEffect(() => {
    if (appointment && activeKey !== "2") {
      setActiveKey("2");
    }
  }, [appointment, activeKey]);

  useEffect(() => {
    const tab = get(history, "location.tab", "");
    if (tab.includes("appointment")) setActiveKey("2");
    else setActiveKey("1");
  }, [history]);

  const onTabClick = (key) => {
    if (appointment && activeKey === "2" && key !== activeKey) {
      setAppointment(null);
    }
    setActiveKey(key);
  };

  const props = {
    Tabs: {
      activeKey,
      onTabClick,
      size: "large",
    },
  };

  return (
    <Tabs {...props.Tabs}>
      <Tabs.TabPane tab='Inicio' key='1'>
        <Home />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Historial de citas' key='2'>
        <Appointment />
      </Tabs.TabPane>
    </Tabs>
  );
}
