import React from "react";
import RowAvatar from "components/RowAvatar";

const columns = (loading) => {
  return [
    {
      title: "Nombre",
      dataIndex: "fullName",
      render: (text, row) => <RowAvatar text={text} row={row} />,
    },
    {
      title: "Teléfono",
      dataIndex: "phoneNumber",
      className: "text-center",
      render: (row) => <div className='mx-auto'>{row || "N/A"}</div>,
    },
    {
      title: "Correo Electronico",
      dataIndex: "email",
      className: "text-right w-1/6",
      width: 180,
      render: (row) => <p className='text-right pb-1 mb-1'>{row}</p>,
    },
  ];
};

export default columns;
