import React from "react";
// antd
import Badge from "antd/lib/badge";
// helpers
import { statusName } from "utils.js/statusColor";
// styles
import style from "./style.module.scss";

export default function BadgeStatus({ status }) {
  const { color, label } = statusName(status);

  const props = {
    color,
    className: style.Badge,
    children: label,
  };

  return <Badge {...props} />;
}
