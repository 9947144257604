export function isMaximumSize(bytes) {
  if (!bytes) return true;

  if (bytes < 4000000) return false;

  let kb = bytes / 1024;
  let mb = kb / 1024;

  return mb > 4.9;
}
