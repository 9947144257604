import React from "react";
import QueueAnim from "rc-queue-anim";
// components
import Info from "./Info";
import Conditions from "./Condition";
import Appointments from "./Appointments";

// styles
import style from "../../style.module.scss";

const props = {
  Queue: {
    key: "tabs",
    type: "bottom",
    component: "section",
    className: style.gridHome,
  },
};

export default function HomeTab() {
  return (
    <QueueAnim {...props.Queue}>
      <Appointments key='1' />
      <Info key='2' />
      <Conditions key='3' />
    </QueueAnim>
  );
}
