import gql from "graphql-tag";

// graphql tag
export default gql`
  query me {
    me {
      id
      firstName
      lastName
      fullName
      verified
      email
      sex
      country
      openFireUsername
      openFirePassword
      belongsToCompany {
        id
      }
      appointmentMD {
        id
        reasonForConsultation
      }
      plans {
        plan {
          id
          name
          beneficiaries
        }
      }
      permissions {
        id
        name
      }
      pictureURL {
        url
      }
      role {
        id
        name
      }
    }
  }
`;
