import React, { useContext, useEffect, useState } from "react";
import { Bubble, GiftedChat, InputToolbar, MessageImage } from "react-gifted-chat";
import { Divider, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { GlobalContext } from "views/App";
import { getUsername, XMPP } from "../../xmpp";
import { CallChatContext } from "chatCall/store";
import CustomComposer from "./CustomComposer";
import SenderFileMessageBubble from "./SenderFileMessageBubble";
import { HandleError } from "utils.js/HandleError";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { sendNotificationNewMessageGql, uploadFileGql } from "./gqlTags";

function ChatScreen({ infoAppointment, infoForChat, isBefore, isAfter, isFinish = false }) {
  const { patient, doctor } = infoAppointment;
  const [messages, setMessages] = useState([]);
  const [patientStatus, setPatientStatus] = useState("offline");
  const [messageLoading, setMessageLoading] = useState(false);
  const { state } = useContext(GlobalContext);
  const [first, setFirst] = useState(null);
  const [end, setEnd] = useState(undefined);
  const [upload] = useMutation(uploadFileGql);
  const [chatInitialize, setChatInitialize] = useState(false);

  const [sendNotificationPushForMessage] = useLazyQuery(sendNotificationNewMessageGql, {
    fetchPolicy: "no-cache",
  });
  var me = {
    _id: doctor?.id,
    name: doctor?.fullName,
    avatar: doctor?.pictureURL?.url,
    username: doctor?.openFireUsername,
    appointmentId: infoAppointment?.id,
  };
  var chatWith = {
    _id: patient?.id,
    name: patient?.fullName,
    avatar: patient?.pictureURL?.url,
    username: patient?.openFireUsername,
    appointmentId: infoAppointment?.id,
  };

  if (state?.user?.id === patient?.id) {
    var temp = me;
    me = chatWith;
    chatWith = temp;
  }

  const { presences, callStatus } = useContext(CallChatContext);

  function scrollToBottom() {
    try {
      const timeout = setTimeout(() => {
        var chatScreen = document.getElementById("chatScreen");
        const child = chatScreen.children[2];
        const el = child.children[0].children[0].children[0];
        el.scroll({
          top: -el.offsetHeight + 100000,
          behavior: "smooth",
        });
        clearTimeout(timeout);
      }, 500);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (callStatus) {
      const message = {
        _id: parseInt(Math.random() * 1000000),
        text: callStatus.toString(),
        createdAt: new Date(),
        user: me,
      };
      setMessages((messages) => {
        const objArray = [...messages, message];
        objArray.sort(function (a, b) {
          return b.createdAt - a.createdAt || 0;
        });
        return [...new Map(objArray.map((item) => [item["_id"], item])).values()];
      });
      scrollToBottom();
    }
  }, [callStatus]);

  useEffect(() => {
    if (presences) {
      const presence =
        presences[getUsername(chatWith.username)] ||
        presences[getUsername(chatWith.username, true, chatWith?.appointmentId)];
      const typing = presences[getUsername(chatWith.username, true, chatWith.appointmentId)];
      if (typing === "typing...") {
        setPatientStatus(typing);
      } else {
        setPatientStatus(presence || "offline");
      }
    }
  }, [presences]);

  function fetchMessages(before) {
    function onFetchMessages(data) {
      if (data?.messages) {
        setMessages((messages) => {
          const objArray = [...messages, ...data?.messages];
          objArray.sort(function (a, b) {
            return b.createdAt - a.createdAt || 0;
          });
          return [...new Map(objArray.map((item) => [item["_id"], item])).values()];
        });
        setFirst(data?.first);
      }
      setMessageLoading(false);
    }
    setMessageLoading(true);
    function onNewMessage(message) {
      setMessages((messages) => {
        const objArray = [...messages, message];
        objArray.sort(function (a, b) {
          return b.createdAt - a.createdAt || 0;
        });
        return [...new Map(objArray.map((item) => [item["_id"], item])).values()];
      });
      scrollToBottom();
    }
    XMPP.fetchMessages(before, me, chatWith, onFetchMessages, onNewMessage);
  }

  function getFirstAndLastMessageId() {
    function onFetchMessages(data) {
      setEnd(data?.first);
      setMessages([]);
      setMessageLoading(false);
    }
    setMessageLoading(true);
    XMPP.getFirstAndLastMessageId(chatWith, onFetchMessages);
  }

  useEffect(() => {
    if (end !== undefined) {
      fetchMessages();
    }
  }, [end]);

  useEffect(() => {
    if (presences[me.username] === "online" && !chatInitialize) {
      getFirstAndLastMessageId();
      XMPP.subscribePresence(chatWith.username);
      setChatInitialize(true);
    }
  }, [presences, chatInitialize]);

  async function sendMessage(messages = []) {
    const message = messages[0];
    setMessages((previousMessages) => GiftedChat.append(previousMessages, messages));
    const timeout = setTimeout(() => {
      scrollToBottom();
      clearTimeout(timeout);
    }, 500);
    XMPP.sendTextMessage(chatWith.username, message, infoAppointment?.id);
    if (patientStatus !== "online") {
      sendNotificationPushForMessage({
        variables: { id: infoAppointment?.id, message: message?.text },
      });
    }
  }

  const [uploadFile, setUploadFile] = useState(null);
  async function sendMediaMessage(file) {
    const mediaMessage = {
      _id: parseInt(Math.random() * 100000),
      createdAt: new Date(),
      user: me,
      file,
      file_type: file?.type.split("/").pop(),
      status: "UPLOADING",
    };
    setUploadFile(mediaMessage);
    try {
      const res = await upload({
        variables: {
          file: file,
          folder: "CHATTING",
          acl: "PUBLIC",
        },
      });
      mediaMessage.status = "SENT";
      mediaMessage.file = res?.data?.uploadFile?.url;
      setUploadFile(null);
      setMessages([mediaMessage, ...messages]);
      XMPP.mediaMessage(chatWith.username, mediaMessage, infoAppointment?.id);
    } catch (error) {
      setUploadFile(null);
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }

  function checkComposerVisible() {
    return (
      (typeof isAfter === "boolean" && isAfter) ||
      (typeof isBefore === "boolean" && isBefore) ||
      (typeof isFinish === "boolean" && isFinish)
    );
  }
  const renderComposer = (props) => {
    if (checkComposerVisible()) return null;
    return (
      <CustomComposer
        {...props}
        chatWith={getUsername(me.username, true, chatWith?.appointmentId)}
        sendMessage={sendMessage}
        sendMediaMessage={sendMediaMessage}
      />
    );
  };

  const renderCustomView = (props) => {
    const file = props.currentMessage.file;
    const file_type = props.currentMessage.file_type;
    const allowedFiles = ["jpg", "jpeg", "png"];

    if (file && allowedFiles.includes(file_type)) {
      if (props.currentMessage.status === "UPLOADING") {
        return <SenderFileMessageBubble message={{ file, file_type }} download={false} />;
      }

      const currentMessage = { ...props.currentMessage, image: file };
      props.imageMessages.push(currentMessage);
      const _props = {
        ...props,
        currentMessage,
      };
      return <MessageImage {..._props} />;
    }

    if (file) {
      if (props.currentMessage.status === "UPLOADING") {
        return <SenderFileMessageBubble message={{ file, file_type }} download={false} />;
      }
      return <SenderFileMessageBubble message={{ file, file_type }} download={true} />;
    }
    return null;
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "#fff",
        wordBreak: "break-all",
      }}
      id='chatScreen'
    >
      <div className='flex flex-row pl-2'>
        <img
          src={chatWith?.avatar}
          style={{ width: 50, height: 50, borderRadius: 50 }}
          alt='avatar'
        />
        <div className='ml-4'>
          <h2>{chatWith?.name}</h2>
          <h5>{patientStatus}</h5>
        </div>
      </div>
      <Divider className='m-0' />
      <GiftedChat
        messages={uploadFile ? [uploadFile, ...messages] : messages}
        // showUserAvatar
        onSend={(messages) => sendMessage(messages)}
        user={me}
        loadEarlier={first !== end}
        onLoadEarlier={() => {
          if (first && !messageLoading) fetchMessages(first);
        }}
        isLoadingEarlier={messageLoading}
        scrollToBottom
        renderCustomView={renderCustomView}
        renderBubble={(props) => {
          if (props.currentMessage.text === "CALL_TIMEOUT") {
            return null;
          }
          const createdAt = props?.currentMessage?.createdAt;
          if (props.currentMessage.text === "CALL_INITIATED") {
            return (
              <div className='flex flex-row w-full justify-center align-items-center'>
                <FontAwesomeIcon className='mx-2' size='lg' icon={faVideo} />
                <h3 style={{ fontSize: "12px" }}>{`inició la llamada a las ${moment(
                  createdAt
                ).format("hh:mm A")}`}</h3>
              </div>
            );
          }
          if (props.currentMessage.text === "CALL_DISCONNECT") {
            return (
              <div className='flex flex-row w-full justify-center align-items-center'>
                <FontAwesomeIcon className='mx-2' size='lg' icon={faVideoSlash} color='red' />
                <h3 style={{ fontSize: "12px" }}>{`desconectada la llamada a las ${moment(
                  createdAt
                ).format("hh:mm A")}`}</h3>
              </div>
            );
          }
          if (props.currentMessage.text === "CALL_REJECTED") {
            return (
              <div className='flex flex-row w-full justify-center align-items-center'>
                <FontAwesomeIcon className='mx-2' size='lg' icon={faVideoSlash} color='red' />
                <h3 style={{ fontSize: "12px" }}>{`rechazada la llamada a las ${moment(
                  createdAt
                ).format("hh:mm A")}`}</h3>
              </div>
            );
          }
          return (
            <Bubble
              {...props}
              textStyle={{
                left: { color: "#fff" },
                right: {
                  color: "#000",
                },
              }}
              wrapperStyle={{
                left: {
                  backgroundColor: "#8BC53F",
                },
                right: {
                  backgroundColor: "#D9D9D9",
                },
              }}
            />
          );
        }}
        infiniteScroll
        alwaysShowSend
        inverted={true}
        shouldUpdateMessage={() => true}
        renderInputToolbar={(props) => (
          <InputToolbar {...props} placeholder='Escribe un mensaje' renderSend={() => null} />
        )}
        isTyping={patientStatus === "typing..."}
        onInputTextChanged={(text) => {
          if (text.trim()) XMPP.startTyping();
        }}
        renderComposer={renderComposer}
      />
    </div>
  );
}

export default ChatScreen;
