import { cloneElement, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import Modal from "antd/lib/modal";
import notification from "antd/lib/notification";
// context
import Context from "./Context";
// helpers
import get from "lodash/get";

export default function HOCDeleteDialog({ children }) {
  const { name, id, gql, onRemove, account, pending } = useContext(Context);

  const [applyRemove, { loading, error }] = useMutation(
    account ? gql.removeAcc : pending ? gql.removePendingBeneficiary : gql.remove,
    {
      variables: { ...((account || !pending) && { id }), ...(pending && { email: id }) },
    }
  );

  const onOk = () => {
    applyRemove()
      .then(() => {
        if (onRemove) onRemove();
        Modal.destroyAll();
        notification.success({
          message: "Se ha removido correctamente",
          description: name,
        });
      })
      .catch(() => {
        Modal.destroyAll();
        notification.error({
          message: "Error",
          description: get(error, ["graphQLErrors", 0, "message"], ""),
        });
      });
  };

  const onClick = () => {
    Modal.confirm({
      onOk,
      title: "Remover de mis Beneficiarios",
      content: name,
      centered: true,
      confirmLoading: loading,
      cancelButtonProps: {
        disabled: loading,
      },
    });
  };

  return cloneElement(children, { onClick });
}
