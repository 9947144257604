import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
// components
import FormCondition from "components/Person/Profile/FormCondition";
// context
import { GlobalContext } from "views/App";
import Context from "views/Patient/Context";
// gql
import {
  getMeConditions,
  muteMeConditions,
  getConditionsBeneficiary,
  muteConditionsBeneficiary,
} from "../../gql";
// helpers
import get from "lodash/get";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
// antd
import Spin from "antd/lib/spin";
import Card from "antd/lib/card";
import Form from "antd/lib/form";
// styles
import style from "../../style.module.scss";

export default function Conditions() {
  const [form] = Form.useForm();
  const { state } = useContext(GlobalContext);
  const { person } = useContext(Context);
  const [initialValues, setInitialValues] = useState({});

  const isMain = state.user.id === person;

  const gql = {
    path: isMain ? "patientById" : "beneficiaryById",
    query: isMain ? getMeConditions : getConditionsBeneficiary,
    mutation: isMain ? muteMeConditions : muteConditionsBeneficiary,
  };

  const stateQuery = useQuery(gql.query, {
    variables: { id: person },
    fetchPolicy: "no-cache",
  });

  const [muteInfo, stateMute] = useMutation(gql.mutation, {
    variables: { id: person },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const { data } = stateQuery;
    if (data) {
      const info = get(data, gql.path, false);
      if (info) setInitialValues(info);
    }
  }, [stateQuery, gql]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  const onValuesChange = (_, variables) => {
    if (isEmpty(initialValues)) return;

    const isDiff = some(variables, (val, key) => {
      return initialValues[key] !== val;
    });

    if (isDiff) {
      muteInfo({ variables })
        .then(() => stateQuery.refetch())
        .catch((e) => console.log(e));
    }
  };

  const props = {
    Card: {
      title: "Historial Médico",
      extra: stateMute.loading || stateQuery.loading ? <Spin /> : null,
      className: style.Conditions,
    },
    Form: {
      form,
      initialValues,
      onValuesChange,
    },
  };

  return (
    <Card {...props.Card}>
      <FormCondition {...props.Form} />
    </Card>
  );
}
