import React from "react";
import Pdf from "components/MedicalPrescription";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
// antd
import Spin from "antd/lib/spin";
import Button from "antd/lib/button";

function BtnPdf({ record }) {
  return (
    <PDFDownloadLink
      className='float-right inline-block'
      document={<Pdf data={record} />}
      fileName={"Diagnostico_" + moment(new Date()).format("DD-MM-YYYY") + ".pdf"}
    >
      {(loading, error) => {
        if (error) {
          console.log(error);
          return null;
        }
        if (loading.loading) {
          return <Spin size='small' />;
        }
        return (
          <Button size='large' type='primary' shape='round'>
            Ver Dx y Receta
          </Button>
        );
      }}
    </PDFDownloadLink>
  );
}

export default BtnPdf;
