import { useEffect, useRef } from "react";

export default function useInterval(callback, delay = 500) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const getRandomInt = () => {
  const miNewDate = new Date();
  const newRandom = Math.floor(Math.random() * 2500);
  return miNewDate.toLocaleTimeString() + "_" + newRandom;
};
