import { XMPP } from "../xmpp";

export const isLoginUser = async () => {
  try {
    const user = await XMPP.getLoggedinUser();
    return user;
  } catch (e) {
    return null;
  }
};

export const logoutUser = async () => {
  try {
    XMPP.logout();
  } catch (e) {
    return null;
  }
};

export const isViewCitaOrOffline = (location) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return true;
  }
  return location.toLowerCase().includes("/cita");
};

export const URLPATIENTNORMAL = "/cita/paciente/llamada/";
export const URLDOCTORNORMAL = "/cita/doctor/llamada/";

export const URLPATIENTCITAMD = "/cita/paciente/consultainmediata/";
export const URLDOCTORCITAMD = "/cita/doctor/consultainmediata/";

export const URLDOCTORMDIC = "/cita/doctormd/interconsulta/";
export const URLDOCTORIC = "/cita/doctor/interconsulta/";
