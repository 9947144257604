import * as actionName from "./actionStore";

import initialState from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionName.SET_STEPS:
      return {
        ...state,
        stepsActive: action.payload,
      };

    case actionName.SET_FINISHWIZARD:
      return {
        ...state,
        finishWizard: action.payload,
      };

    case actionName.SET_SPECIALTYACTIVE:
      return {
        ...state,
        specialtyActive: action.payload,
      };

    case actionName.SET_APPOINTMENTS_METHOD:
      return {
        ...state,
        appointmentsMethod: action.payload,
      };

    case actionName.SET_SYMPTOMS:
      return {
        ...state,
        detailsAppointments: {
          ...state.detailsAppointments,
          symptoms: action.payload,
        },
      };

    case actionName.SET_REASON_APPOINTMENTS:
      return {
        ...state,
        detailsAppointments: {
          ...state.detailsAppointments,
          reasonAppointments: action.payload,
        },
      };

    case actionName.SET_LOADINGGLOBAL:
      return {
        ...state,
        loadingGlobal: action.payload,
      };

    case actionName.SET_DRUGALLERGIES:
      return {
        ...state,
        detailsAppointments: {
          ...state.detailsAppointments,
          DrugAllergies: action.payload,
        },
      };

    case actionName.SET_MEDICALCONDITIONS:
      return {
        ...state,
        detailsAppointments: {
          ...state.detailsAppointments,
          MedicalConditions: action.payload,
        },
      };

    default:
      return { ...state };
  }
};

export default reducer;
