import React, { useEffect } from "react";

import { Modal, Button, Form, Input, Alert, Divider, notification } from "antd";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

const requestPasswordReset = gql`
  query requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

const ModalPassword = (props) => {
  const [form] = Form.useForm();
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [senEmail, { loading, error, data }] = useLazyQuery(requestPasswordReset, {
    fetchPolicy: "no-cache",
  });
  const { setVisbleModalReset } = props;

  const onFinishForm = (formValue) => {
    const { email } = formValue;
    senEmail({ variables: { email: email } });
  };

  useEffect(() => {
    if (!loading && !error && !!data) {
      setVisbleModalReset(false);
      notification.success({
        message:
          "Si su correo electrónico está registrado en nuestra plataforma, recibirá un correo electrónico con las instrucciones para restablecer su contraseña.",
      });
    }
  }, [data, loading, error, setVisbleModalReset]);

  return (
    <Modal
      title='Forgot password'
      closable={false}
      visible={props.visbleModalReset}
      onCancel={(e) => props.setVisbleModalReset(false)}
      footer={null}
    >
      <Form form={form} name='ForgotPassword' onFinish={(e) => onFinishForm(e)} scrollToFirstError>
        <p>
          Por favor ingresa la dirección de correo electrónico registrada en la plataforma para
          cambiar tu contraseña.
        </p>
        <Form.Item
          label={null}
          name='email'
          rules={[
            {
              required: true,
              message: "Por favor ingrese su correo electrónico!",
            },
            {
              pattern: regexEmail,
              message: "Por favor ingrese un correo valido!",
            },
          ]}
        >
          <Input
            size='large'
            prefix={<FontAwesomeIcon className='text-center mr-2' size='lg' icon={faEnvelope} />}
            className='rounded-lg'
            placeholder='Email'
          />
        </Form.Item>

        {error && !data && (
          <Alert
            message='Error'
            description='Se produjo un error al procesar su solicitud. Por favor, inténtelo de nuevo más tarde.'
            type='error'
            closable
            showIcon
          />
        )}

        <Divider className='w-full' />

        <div className='flex justify-between h-full'>
          <Form.Item className='my-auto'>
            <Button
              loading={loading}
              disabled={loading}
              shape='round'
              key='cancel'
              onClick={() => props.setVisbleModalReset(false)}
            >
              Cerrar
            </Button>
          </Form.Item>
          <Form.Item className='my-auto'>
            <Button
              loading={loading}
              disabled={error || data}
              htmlType='submit'
              shape='round'
              key='send'
              type='primary'
            >
              Enviar
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalPassword;
