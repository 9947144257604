import React, { useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
// antd
import Empty from "antd/lib/empty";
import Button from "antd/lib/button";
import Notify from "antd/lib/notification";
// components
import Title from "../Title";
import ExamCard from "./Card";
import DialogAdd from "./DialogAdd";
// context
import Context from "../Context";
// gql
import { deleteAttach, attachFile } from "../../../../gql";
// helpers
import isEmpty from "lodash/isEmpty";

const props = {
  Title: {
    children: "Adjuntar examenes",
  },
  Empty: {
    className: "mx-auto",
    description: "sin examenes",
  },
  Btn: {
    type: "dashed",
    block: true,
    small: "large",
    children: <span className='ml-3'>Adjuntar examen</span>,
    className: "mt-2 text-success border-primary",
  },
};

export default function Attachment() {
  const { exams, id, editable } = useContext(Context);
  const [remove] = useMutation(deleteAttach, {
    awaitRefetchQueries: true,
    refetchQueries: ["getMeAppointmentsDetail"],
  });
  const [attach, state] = useMutation(attachFile, {
    awaitRefetchQueries: true,
    refetchQueries: ["getMeAppointmentsDetail"],
  });

  const notifyError = (e) => {
    console.log(e);
    Notify.error({ message: "Error en la operación con el adjunto" });
  };

  const onRemove = (id) => {
    const variables = { id };
    remove({ variables })
      .then(() => {
        Notify.success({ message: "Archivo removido con exito!" });
      })
      .catch(notifyError);
  };

  const onAdd = ({ title, file }) => {
    const variables = {
      file,
      title,
      appointment: id,
    };

    return attach({ variables })
      .then(() => {
        Notify.success({ message: "Archivo agregado con exito!" });
      })
      .catch(notifyError);
  };

  return (
    <>
      <Title {...props.Title} />
      {isEmpty(exams) ? (
        <Empty {...props.Empty} />
      ) : (
        <div style={{ maxHeight: 175, overflow: "auto" }}>
          {exams.map((o) => {
            const p = { key: o.id, ...o, onRemove, editable };
            return <ExamCard {...p} />;
          })}
        </div>
      )}
      {editable && (
        <DialogAdd mutation={onAdd} state={state}>
          <Button {...props.Btn} />
        </DialogAdd>
      )}
    </>
  );
}
