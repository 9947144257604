import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
// antd
import Dropdown from "antd/lib/dropdown";
// components
import menu from "./Menu";
import Avatar from "./Avatar";

// context
import { GlobalContext } from "views/App";

//utils
import { logoutAndClearAll } from "layouts/utils";

export default function User({ size, children }) {
  const {
    state: { user },
    dispatch,
  } = useContext(GlobalContext);
  const history = useHistory();

  if (!user) return null;

  const action = {
    onLogout: () => {
      logoutAndClearAll();
      dispatch({ type: "SETUSER", payload: null });
      history.push("/login");
      if (window?.location) {
        window.location.reload();
      }
    },
    goTo: (state) => history.push(state),
  };

  const props = {
    Dropdown: {
      overlay: menu(action, user),
      children: children || (
        // flex flex-row justify-center items-center
        // Estas clases son para el responsive en resoluciones tablet e inferior
        <div className='flex flex-row justify-center items-center'>
          <span className='mr-2'>{user.fullName}</span>
          <Avatar src={user.pictureURL.url} size={size} />
        </div>
      ),
      placement: "bottomRight",
      overlayClassName: "rounded-lg overflow-hidden",
    },
  };

  return <Dropdown {...props.Dropdown} />;
}
