import React from "react";
import moment from "moment";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";

// helpers
import get from "lodash/get";

const LogoUrl= 'https://wl-asssets.s3.us-east-1.amazonaws.com/' + process.env.REACT_APP_COMPANY_LOGO;

export default ({ data = {} }) => {
  const {
    medic: { name: fullName = "", specialties = [], minsaNumber = "", signature } = {},
    patient: { name: patientFullName = "", dateOfBirth = moment(), nationalId } = {},
    date: appointmentDateAndTime = moment(),
    diagnosis = "",
    prescription = [],
    appointmentNumber,
  } = data;

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={[styles.heroSection, { marginVertical: "20px", flexDirection: "row" }]}>
          <View style={{ width: "50%", justifyContent: "center" }}>
            <Image style={{ width: "50%" }} src={LogoUrl} />
          </View>
          <View style={{ width: "50%", alignItems: "flex-end" }}>
            <View style={{ flexDirection: "col", alignItems: "flex-end" }}>
              <Text style={styles.header}>Dr. {fullName}</Text>
              <Text style={[styles.subHeader]}>
                {specialties
                  .map((u) => u.name)
                  .toString()
                  .replace(",", ", ")}
              </Text>
              <Text style={styles.regular}>Registro: {minsaNumber}</Text>
              <Text style={{ fontSize: 18, color: "red", marginTop: 5 }}>
                N. {appointmentNumber}
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.detailsSection, { marginVertical: "20px" }]}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "70%", justifyContent: "center" }}>
              <Text>Paciente: {patientFullName}</Text>
              <Text>
                Fecha de nacimiento:{" "}
                {dateOfBirth ? moment(dateOfBirth).format("MM-DD-YYYY") : "N/A"}
              </Text>
              {nationalId && <Text>Identificación: {nationalId}</Text>}
            </View>
            <View style={{ width: "30%", alignItems: "center" }}>
              <Text style={{ textTransform: "capitalize" }}>
                Fecha de la cita: {moment(appointmentDateAndTime).format("LLLL")}
              </Text>
            </View>
          </View>
          <View style={[{ marginVertical: "20px", flexDirection: "row" }]}>
            <View style={{ width: "100%", justifyContent: "center" }}>
              <Text style={[styles.subHeader]}>Diagnostico:</Text>
              <Text>{diagnosis}</Text>
            </View>
          </View>
          <View style={[{ marginVertical: "20px", flexDirection: "row" }]}>
            <View style={{ width: "100%", justifyContent: "center" }}>
              <Text style={[styles.subHeader]}>Prescripcion:</Text>
              {prescription.map((item, i) => (
                <Text style={{ lineHeight: 1.3 }} key={i}>
                  {i + 1}. {item}
                </Text>
              ))}
            </View>
          </View>
          <View style={[styles.firma, { flexDirection: "col", alignItems: "flex-end" }]}>
            <View style={styles.picFirma}>
              <Image style={{ width: "100%" }} source={get(signature, "url", "")} />
            </View>
            <View
              style={[
                styles.textFirma,
                { width: "30%", alignItems: "flex-start", justifyContent: "flex-start" },
              ]}
            >
              <Text style={{ textAlign: "center" }}>FIRMA DEL DOCTOR</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    flex: 1,
    fontSize: "12pt",
  },
  header: {
    fontSize: "18pt",
    color: "#10B5DC",
    lineHeight: 1.2,
  },
  subHeader: {
    fontSize: "12pt",
    lineHeight: 1.2,
    textTransform: "uppercase",
  },
  regular: {
    fontSize: "10pt",
    lineHeight: 1.1,
  },
  heroSection: {
    margin: 10,
    padding: 15,
  },
  detailsSection: {
    margin: 10,
    padding: 15,
    borderColor: "#10B5DC",
    borderRadius: 20,
    borderWidth: 1,
    flex: 1,
  },
  firma: {
    position: "absolute",
    bottom: 20,
    width: "100%",
  },
  picFirma: {
    width: "30%",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
  },
  textFirma: {
    borderTopWidth: 1,
    borderColor: "#10B5DC",
    paddingVertical: 10,
    textAlign: "center",
  },
});
