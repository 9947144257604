import { useState, useEffect, useContext } from "react";

// lodash
import { isNull, isString } from "lodash";

import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { appointmentMDGql, appointmentStatusGql } from "./gqlTags";

// context
import { GlobalContext } from "../../App";

// utils
import { verifyIdUserVsPatient, verifyUsable, verifyFinish, verifyRenderChat } from "./utils";

const UseValidatorCita = (id) => {
  const { state } = useContext(GlobalContext);
  const { user, chatCall: chatCallGlobalState } = state;

  const [dataAppointment, setDataAppointment] = useState(null);
  const [notFound, setNotFound] = useState(null);
  const [isUsable, setIsUsable] = useState(null);
  const [isRenderChat, setIsRenderChat] = useState(null);
  const [isFinish, setIsFinish] = useState(null);
  const [infoForChat, setInfoForChat] = useState(null);

  const [getInfoAppointment, { loading, data, error, refetch: refetchgetInfoAppointment }] =
    useLazyQuery(appointmentMDGql, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    });

  const {
    loading: loadingStatus,
    error: errorStatus,
    data: dataStatus,
    startPolling,
    stopPolling,
    refetch,
  } = useQuery(appointmentStatusGql, {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    refetch();
  }, [chatCallGlobalState, refetch]);

  useEffect(() => {
    startPolling(16000);
    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    if (isNull(id) || !isString(id)) {
      setNotFound(true);
    } else {
      getInfoAppointment({
        variables: {
          id: id,
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (!loadingStatus) {
      if (dataStatus) {
        const { status } = dataStatus.appointmentmdById;
        const isUsableAppointment = verifyUsable(status);
        const isFinishAppointment = verifyFinish(status);
        const isRenderChat = verifyRenderChat(status);
        setIsFinish(isFinishAppointment);
        setIsRenderChat(isRenderChat);
        setIsUsable(isUsableAppointment);
        if (isFinishAppointment) {
          stopPolling();
        } else {
          if (dataStatus.appointmentmdById.doctor?.id) {
            refetchgetInfoAppointment();
          }
          const userChat = {
            hasBlockedMe: false,
            blockedByMe: false,
            name: dataStatus.appointmentmdById.doctor
              ? dataStatus.appointmentmdById.doctor.fullName
              : "",
            role: "default",
            avatar: dataStatus.appointmentmdById.doctor
              ? dataStatus.appointmentmdById.doctor.pictureURL.url
              : "",
            username: dataStatus?.appointmentmdById?.doctor?.openFireUsername,
          };
          setInfoForChat(userChat);
        }
      } else if (errorStatus) {
        setNotFound(true);
      }
    }
  }, [loadingStatus, dataStatus, errorStatus, stopPolling]);

  useEffect(() => {
    if (!loading) {
      if (data) {
        const isValidId = verifyIdUserVsPatient(user.id, data.appointmentmdById.patient.id);
        const userChat = {
          hasBlockedMe: false,
          blockedByMe: false,
          name: data.appointmentmdById.doctor ? data.appointmentmdById.doctor.fullName : "",
          role: "default",
          avatar: data.appointmentmdById.doctor ? data.appointmentmdById.doctor.pictureURL.url : "",
        };
        setInfoForChat(userChat);
        if (isValidId) {
          setDataAppointment(data.appointmentmdById);
        } else {
          setNotFound(true);
        }
      } else if (error) {
        setNotFound(true);
      }
    }
  }, [loading, data, error, user]);

  return {
    dataAppointment: dataAppointment,
    isError: error || errorStatus,
    notFound: notFound,
    isUsable: isUsable,
    isFinish: isFinish,
    isRenderChat: isRenderChat,
    infoForChat: infoForChat,
    loadingBrand: loading || (!loading && !data && !error),
    refetchStatus: refetch,
  };
};

export default UseValidatorCita;
