import React from "react";
import { Layout } from "antd";
import { Helmet } from "react-helmet";
import HeaderHome from "./headerHome";
import FooterHome from "./footer";
import WhatsAppChat from "../../components/WhatsAppChat";
import LoginMD from "../../views/microSalud/Login";
import { COMPANY_NAME, COMPANY_LOGO } from "utils.js/constants";

const { Content } = Layout;

const HomeLayout = () => {
  return (
    <Layout className='layout bg-white'>
      <Helmet>
        <title>{COMPANY_NAME}</title>
        <link
          rel='shortcut icon'
          href={`https://wl-asssets.s3.us-east-1.amazonaws.com/${COMPANY_LOGO.replace(
            ".png",
            ".ico"
          )}`}
        />
      </Helmet>
      <HeaderHome />
      <Content>
        <div className='site-layout-content'>
          <LoginMD />
        </div>
      </Content>
      <FooterHome />
      <WhatsAppChat />
    </Layout>
  );
};

export default HomeLayout;
