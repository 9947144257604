import React from "react";
// antd
import Input from "antd/lib/input";
import Button from "antd/lib/button";
// components
import InputFile from "./InputFile";
// icons
import { UploadOutlined } from "@ant-design/icons";

export default {
  name: {
    name: "title",
    label: "Nombre del examen méndico",
    rules: [
      {
        required: true,
        message: "Por favor ingrese un Nombre para el Examen",
      },
    ],
    children: <Input placeholder='Nombre del Examen médic' />,
  },
  file: {
    name: "file",
    rules: [
      {
        required: true,
        message: "Por favor seleccionar archivo",
      },
    ],
    children: (
      <InputFile>
        <Button>
          <UploadOutlined /> Seleccionar el archivo
        </Button>
      </InputFile>
    ),
  },
};
