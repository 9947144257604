import React, { useState, useContext, useEffect, useCallback } from "react";
import CardRadio from "../Component/CardRadio";

import { wizardContext } from "../ModalWizard/ContendModal";
import * as actionName from "../Store/actionStore";

import Spin from "antd/lib/spin";

// icons Custom
import chatIcon from "assets/customIcons/Chat.svg";
import chatColorIcon from "assets/customIcons/Chat-hover.svg";
import videoIcon from "assets/customIcons/video.svg";
import videoColorIcon from "assets/customIcons/video-hover.svg";

import get from "lodash/get";
import toLowerCase from "lodash/toLower";
import includes from "lodash/includes";

import { GlobalContext } from "views/App";

const Steps3 = () => {
  const [focusButton, setFocusButton] = useState(null);
  const [loadingValidationplan, setLoadingValidationPlan] = useState(true);
  const [propsCard, setPropsCard] = useState([
    {
      title: "Chat",
      iconSrc: [chatIcon, chatColorIcon],
      key: "CHAT",
    },
    {
      title: "Video llamada",
      iconSrc: [videoIcon, videoColorIcon],
      key: "CALL",
    },
  ]);
  const { state, dispatch } = useContext(wizardContext);
  const { state: globalState } = useContext(GlobalContext);

  const verifyplanTigo = useCallback(() => {
    const plansArray = get(globalState, ["user", "plans"], []);
    plansArray.forEach((value) => {
      if (value?.plan?.name) {
        if (
          includes(toLowerCase(value?.plan?.name), "plan tigo") ||
          includes(toLowerCase(value?.plan?.name), "plan demo tigo")
        ) {
          // only one option for select
          // CHAT enabled
          // CALL removed
          setPropsCard([
            {
              title: "Chat",
              iconSrc: [chatIcon, chatColorIcon],
              key: "CHAT",
            },
          ]);
        }
      }
    });
    setLoadingValidationPlan(false);
  }, [globalState]);

  useEffect(() => {
    setFocusButton(state.appointmentsMethod);
  }, [state.appointmentsMethod]);

  useEffect(() => {
    if (loadingValidationplan) {
      verifyplanTigo();
    }
  }, [globalState, loadingValidationplan, verifyplanTigo]);

  const setMethod = (payload) => {
    return dispatch({
      type: actionName.SET_APPOINTMENTS_METHOD,
      payload: payload,
    });
  };

  return (
    <>
      <div className='flex justify-center w-full mt-10 mb-8'>
        <h3 style={{ color: "#707070" }} className='text-xl'>
          Método de la Consulta
        </h3>
      </div>
      {loadingValidationplan ? (
        <div className='min-h-200px w-full flex justify-center items-center'>
          <Spin />
        </div>
      ) : (
        <CardRadio
          focusButton={focusButton}
          propsCard={propsCard}
          onClickCard={(NewSpecialty) => setMethod(NewSpecialty)}
        />
      )}
    </>
  );
};

export default Steps3;
