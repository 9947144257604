import React, { useLayoutEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./style.scss";

export default function WhatsAppChat() {
  const [mounted, setMounted] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 1500);
  }, []);

  return (    
    <>
    { process.env.REACT_APP_COMPANY_WHATSAPP && 
      <a
        href={'https://wa.me/+505' + process.env.REACT_APP_COMPANY_WHATSAPP}
        className={`whatsapp_float ${!mounted ? "slideUp" : null} shake`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <FontAwesomeIcon className='whatsapp-icon' icon={faWhatsapp} />
      </a>
    }
    </>  
  );
}
