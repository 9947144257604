import React from "react";
import ReactDOM from "react-dom";
import "./assets/main.css";
import "./assets/relaunch.css";
import App from "./views/App";
import * as serviceWorker from "./serviceWorker";
import "font-awesome/css/font-awesome.min.css";
import { AppContainer } from "react-hot-loader";
import { NODE_ENV } from "./utils.js/constants";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

const render = () => {
  ReactDOM.render(
    NODE_ENV === "development" ? (
      <AppContainer>
        <App />
      </AppContainer>
    ) : (
      <App />
    ),
    document.getElementById("root")
  );
};

render();

if (NODE_ENV === "development" && module.hot) {
  module.hot.accept("./views/App", () => {
    render();
  });
}
