import React, { useContext } from "react";
import Button from "antd/lib/button";
// components
import HOCDeleteDialog from "./DeleteDialog";
// icons
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronRight, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
// contexts
import Context from "./Context";
import { GlobalContext } from "views/App";

export default function Extras() {
  const { selected, id, gql } = useContext(Context);
  const { state } = useContext(GlobalContext);

  const canDelete = id !== state.user.id || gql?.remove || gql?.removeAcc;

  const props = {
    Btn: {
      type: "text",
      icon: <FA icon={faTrashAlt} />,
      danger: true,
    },
    Status: {
      icon: selected ? faCheck : faChevronRight,
    },
  };

  return (
    <>
      {canDelete && (
        <HOCDeleteDialog>
          <Button {...props.Btn} />
        </HOCDeleteDialog>
      )}
      <FA {...props.Status} />
    </>
  );
}
