import React from "react";
import Modal from "antd/lib/modal";
import ContendModal from "./ContendModal";
import InitialSteps from "../Steps/InitialSteps";
import Button from "antd/lib/button";
import Link from "react-router-dom/Link";

const ModalWizard = (props) => {
  const { visbleModal, onClose, user } = props;
  return (
    <Modal
      destroyOnClose
      style={{ minWidth: "20rem" }}
      bodyStyle={{ paddingBottom: "0", minHeight: "500px" }}
      className='w-9/12 md:w-10/12 lg:w-6/12'
      // width='50vw'
      title={null}
      footer={null}
      visible={visbleModal}
      onOk={() => console.log("sumit ok")}
      onCancel={() => onClose()}
    >
      {user ? (
        <ContendModal onClose={onClose} />
      ) : (
        <>
          <InitialSteps />
          <div className='mt-16 pb-6 flex justify-center md:justify-end'>
            <Button type='default' shape='round' className='button-success' size='large'>
              <Link onClick={() => onClose()} to='/md/planesEasyMD'>
                Ver Planes
              </Link>
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ModalWizard;
