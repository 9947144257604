/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, keyframes } from "@emotion/core";
import PropTypes from "prop-types";
import { XMPP } from "../../xmpp";

import { withApollo } from "@apollo/react-hoc";
import gql from "graphql-tag";

import "emoji-mart/css/emoji-mart.css";

import {
  validateWidgetSettings,
  checkMessageForExtensionsData,
  ID,
  getUnixTimestamp,
} from "./util/common";
import * as enums from "./util/enums";

import { theme } from "./resources/theme";
import Translator from "./resources/localization/translator";
import { outgoingMessageAlert } from "./resources/audio/";

import { message } from "antd";

import {
  chatComposerStyle,
  editPreviewContainerStyle,
  previewHeadingStyle,
  previewCloseStyle,
  previewTextStyle,
  composerInputStyle,
  inputInnerStyle,
  messageInputStyle,
  inputStickyStyle,
  stickyAttachmentStyle,
  filePickerStyle,
  fileListStyle,
  fileItemStyle,
  stickyAttachButtonStyle,
  stickyButtonStyle,
  emojiButtonStyle,
  sendButtonStyle,
} from "./style";

import roundedPlus from "./resources/attach.png";
import videoIcon from "./resources/attachvideo.png";
import audioIcon from "./resources/attachaudio_2.png";
import docIcon from "./resources/attachfile.png";
import imageIcon from "./resources/attachimage.png";
import insertEmoticon from "./resources/insertemoji.png";
import sendBlue from "./resources/sendmessage.png";
import closeIcon from "./resources/close.png";
import ChatEmojiKeyboard from "./ChatEmojiKeyboard";

class CustomComposer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.imageUploaderRef = React.createRef();
    this.fileUploaderRef = React.createRef();
    this.audioUploaderRef = React.createRef();
    this.videoUploaderRef = React.createRef();
    this.messageInputRef = React.createRef();

    this.isTyping = false;

    this.state = {
      showFilePicker: false,
      messageInput: "",
      messageType: "",
      emojiViewer: false,
      createPoll: false,
      messageToBeEdited: "",
      replyPreview: null,
      stickerViewer: false,
      messageToReact: "",
      shareDocument: false,
      shareWhiteboard: false,
      sendFirtsMessage: this.props.sendFirstMessage || false, //custom
    };

    this.audio = new Audio(outgoingMessageAlert);
  }

  componentDidMount() {
    if (this.messageInputRef && this.messageInputRef.current) {
      this.messageInputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.messageToBeEdited !== this.props.messageToBeEdited) {
      const messageToBeEdited = this.props.messageToBeEdited;

      this.setState({
        messageInput: messageToBeEdited,
        messageToBeEdited: messageToBeEdited,
      });

      const element = this.messageInputRef.current;
      if (messageToBeEdited) {
        let messageText = messageToBeEdited.text;

        //xss extensions data
        const xssData = checkMessageForExtensionsData(messageToBeEdited, "xss-filter");
        if (
          xssData &&
          xssData.hasOwnProperty("sanitized_text") &&
          xssData.hasOwnProperty("hasXSS") &&
          xssData.hasXSS === "yes"
        ) {
          messageText = xssData.sanitized_text;
        }

        element.focus();
        element.textContent = "";
        this.pasteHtmlAtCaret(messageText, false);
      } else {
        element.textContent = "";
      }
    }

    if (prevProps.replyPreview !== this.props.replyPreview) {
      this.setState({ replyPreview: this.props.replyPreview });
    }

    const previousMessageStr = JSON.stringify(prevProps.messageToReact);
    const currentMessageStr = JSON.stringify(this.props.messageToReact);

    if (previousMessageStr !== currentMessageStr) {
      this.setState({ messageToReact: this.props.messageToReact });
    }

    if (prevProps.item !== this.props.item) {
      this.messageInputRef.current.textContent = "";
      this.setState({
        stickerViewer: false,
        emojiViewer: false,
        replyPreview: null,
        messageToBeEdited: "",
        messageInput: "",
      });

      this.focusOnMessageComposer();
    }

    if (prevState.messageInput !== this.state.messageInput) {
      this.focusOnMessageComposer();
    }
  }

  formatFileSize = (bytes, decimalPoint = 2) => {
    if (bytes === 0) {
      return {
        size: 0,
        index: 0,
      };
    }
    const k = 1024;
    const dm = decimalPoint || 2;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return {
      size: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
      index: i,
    };
  };

  openMessageFileSize = () => {
    message.error({
      content: "El tamaño del archivo no debe superar los 15 MegaBytes",
      duration: 5,
    });
  };

  openMessageNoTypeFile = () => {
    message.error({
      content: "Este tipo de archivo no esta permitido",
      duration: 5,
    });
  };

  openMessageUploadFile = () => {
    message.info({
      content:
        "Subiendo archivo seleccionado, esto puede tardar unos minutos en aparecer en el chat.",
      duration: 8,
    });
  };

  focusOnMessageComposer = () => {
    if (this.messageInputRef && this.messageInputRef.current) {
      this.messageInputRef.current.focus();
    }
  };

  playAudio = () => {
    //if message sound is disabled for chat wigdet in dashboard
    if (validateWidgetSettings(this.props.widgetsettings, "enable_sound_for_messages") === false) {
      return false;
    }

    this.audio.currentTime = 0;
    this.audio.play();
  };

  pasteHtmlAtCaret(html, selectPastedContent) {
    var sel, range;
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection();
      if (this.props.widgetsettings && this.props.hasOwnProperty("widgetsettings")) {
        const parentnode = this.props.widgetsettings.hasOwnProperty("parentNode")
          ? this.props.widgetsettings.parentNode
          : null;
        if (parentnode) {
          sel = parentnode.querySelector("iframe").contentWindow.getSelection();
        }
      }
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();

        // Range.createContextualFragment() would be useful here but is
        // only relatively recently standardized and is not supported in
        // some browsers (IE9, for one)
        var el = document.createElement("div");
        el.innerText = html;
        var frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        var firstNode = frag.firstChild;
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          if (selectPastedContent) {
            range.setStartBefore(firstNode);
          } else {
            range.collapse(true);
          }
          sel.removeAllRanges();
          sel.addRange(range);
        }
      }
    } else if ((sel = document.selection) && sel.type !== "Control") {
      // IE < 9
      var originalRange = sel.createRange();
      originalRange.collapse(true);
      sel.createRange().pasteHTML(html);
      if (selectPastedContent) {
        range = sel.createRange();
        range.setEndPoint("StartToStart", originalRange);
        range.select();
      }
    }
  }

  emojiClicked = (emoji, event) => {
    if (this.state.messageToReact) {
      this.reactToMessages(emoji);
      return;
    }

    const element = this.messageInputRef.current;
    element.focus();
    this.pasteHtmlAtCaret(emoji.native, false);
    this.setState({ messageInput: element.innerText, messageType: "text" });
  };

  changeHandler = (event) => {
    this.startTyping();

    const elem = event.currentTarget;

    const findUlr = elem.querySelector("a");
    let linkText = null;

    try {
      if (findUlr) {
        linkText = findUlr.href.toString();
      }
    } catch (e) {
      linkText = null;
    }

    let messageInput = linkText ? linkText : elem.textContent.trim();

    if (linkText) {
      event.currentTarget.textContent = messageInput;
    }

    if (!messageInput.length) {
      event.currentTarget.textContent = messageInput;
    }

    this.setState({
      messageInput: messageInput ? messageInput.trim() : "",
      messageType: XMPP.MESSAGE_TYPE.TEXT,
    });
  };

  toggleFilePicker = () => {
    const currentState = !this.state.showFilePicker;
    this.setState({ showFilePicker: currentState });
  };

  openFileDialogue = (fileType) => {
    switch (fileType) {
      case "image":
        this.imageUploaderRef.current.click();
        break;
      case "file":
        this.fileUploaderRef.current.click();
        break;
      case "audio":
        this.audioUploaderRef.current.click();
        break;
      case "video":
        this.videoUploaderRef.current.click();
        break;
      default:
        break;
    }
  };

  onImageChange = (e) => {
    if (!this.imageUploaderRef.current.files["0"]) {
      return false;
    }

    /* Custom validation */

    const validation = this.isValidFileValidation(this.imageUploaderRef.current.files["0"]);

    if (!validation) {
      return false;
    }

    /* Custom validation */

    const uploadedFile = this.imageUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      (event) => {
        // Setting up base64 URL on image

        const newFile = new File([reader.result], uploadedFile.name, uploadedFile);
        this.sendMediaMessage(newFile, XMPP.MESSAGE_TYPE.IMAGE);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  isValidFileValidation = (fileInput) => {
    try {
      const { size, type } = fileInput;

      if (type && !type.toLowerCase().includes("application/pdf")) {
        if (type.toLowerCase().includes("application") || type.toLowerCase().includes("window")) {
          this.openMessageNoTypeFile();
          return false;
        }
      }

      const calcSize = this.formatFileSize(size);

      // evitar que suban archivos de GB
      if (calcSize.index > 2 || calcSize.size === 0) {
        this.openMessageFileSize();
        return false;
      }

      // evitar que subar archivos que pesen +15mb
      if (calcSize.index === 2 && calcSize.size > 15) {
        this.openMessageFileSize();
        return false;
      }

      // Alerta en archivos que pesan +4mb
      if (calcSize.index === 2 && calcSize.size > 4) {
        this.openMessageUploadFile();
      }

      return true;
    } catch (e) {
      return false;
    }
  };

  onFileChange = (e) => {
    if (!this.fileUploaderRef.current.files["0"]) {
      return false;
    }

    /* Custom validation */

    const validation = this.isValidFileValidation(this.fileUploaderRef.current.files["0"]);

    if (!validation) {
      return false;
    }

    /* Custom validation */

    const uploadedFile = this.fileUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      (event) => {
        // Setting up base64 URL on image

        const newFile = new File([reader.result], uploadedFile.name, uploadedFile);
        this.sendMediaMessage(newFile, XMPP.MESSAGE_TYPE.FILE);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onAudioChange = (e) => {
    if (!this.audioUploaderRef.current.files["0"]) {
      return false;
    }

    /* Custom validation */

    const validation = this.isValidFileValidation(this.audioUploaderRef.current.files["0"]);

    if (!validation) {
      return false;
    }

    /* Custom validation */

    const uploadedFile = this.audioUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      () => {
        // Setting up base64 URL on image

        const newFile = new File([reader.result], uploadedFile.name, uploadedFile);
        this.sendMediaMessage(newFile, XMPP.MESSAGE_TYPE.AUDIO);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  onVideoChange = (e) => {
    if (!this.videoUploaderRef.current.files["0"]) {
      return false;
    }

    /* Custom validation */

    const validation = this.isValidFileValidation(this.videoUploaderRef.current.files["0"]);

    if (!validation) {
      return false;
    }

    /* Custom validation */

    const uploadedFile = this.videoUploaderRef.current.files["0"];

    var reader = new FileReader(); // Creating reader instance from FileReader() API
    reader.addEventListener(
      "load",
      () => {
        // Setting up base64 URL on image

        const newFile = new File([reader.result], uploadedFile.name, uploadedFile);
        this.sendMediaMessage(newFile, XMPP.MESSAGE_TYPE.VIDEO);
      },
      false
    );

    reader.readAsArrayBuffer(uploadedFile);
  };

  getReceiverDetails = () => {
    let receiverId;
    let receiverType;

    if (this.props.type === XMPP.RECEIVER_TYPE.USER) {
      receiverId = this.props.item.uid;
      receiverType = XMPP.RECEIVER_TYPE.USER;
    } else if (this.props.type === XMPP.RECEIVER_TYPE.GROUP) {
      receiverId = this.props.item.guid;
      receiverType = XMPP.RECEIVER_TYPE.GROUP;
    }

    return { receiverId: receiverId, receiverType: receiverType };
  };

  getConversationId = () => {
    let conversationId = null;

    if (this.props.type === XMPP.RECEIVER_TYPE.USER) {
      const users = [this.props.loggedInUser.uid, this.props.item.uid];
      conversationId = users.sort().join("_user_");
    } else if (this.props.type === XMPP.RECEIVER_TYPE.GROUP) {
      conversationId = `group_${this.props.item.guid}`;
    }

    return conversationId;
  };

  sendMediaMessage = async (messageInput, messageType) => {
    this.toggleFilePicker();
    this.endTyping(null, null);
    this.playAudio();
    this.props.sendMediaMessage(messageInput);
    return;
  };

  // custom method
  sentNotificationToAPI = () => {
    try {
      this.setState({ sendFirtsMessage: false });

      const updateLogGQL = () => {
        const schema = `mutation updateLogMessages($id: String!) {
                    updateLogMessages(id: $id)
              }
            `;
        return gql`
          ${schema}
        `;
      };

      this.props.client.query({
        query: updateLogGQL(),
        variables: { id: this.props.metaData?.idAppointment || "" },
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      });
    } catch (e) {
      console.log("ERROR");
    }
  };

  sendMessageOnEnter = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.sendTextMessage();
      return true;
    }
  };

  sendTextMessage = () => {
    const { messageIdGenerator, user } = this.props;
    if (this.state.emojiViewer) {
      this.setState({ emojiViewer: false });
    }

    if (!this.state.messageInput.trim().length) {
      return false;
    }

    if (this.state.messageToBeEdited) {
      this.editMessage();
      return false;
    }

    this.endTyping(null, null);

    let messageInput = this.state.messageInput.trim();

    this.props.sendMessage([
      {
        _id: messageIdGenerator(),
        createdAt: new Date(),
        text: messageInput.trim(),
        user: user,
      },
    ]);

    this.setState({ messageInput: "", replyPreview: false });

    this.messageInputRef.current.textContent = "";
    this.playAudio();
  };

  editMessage = () => {
    this.endTyping(null, null);

    const messageToBeEdited = this.props.messageToBeEdited;

    let { receiverId, receiverType } = this.getReceiverDetails();
    let messageText = this.state.messageInput.trim();
    let textMessage = new XMPP.TextMessage(receiverId, messageText, receiverType);
    textMessage.setId(messageToBeEdited.id);

    this.setState({ messageInput: "" });
    this.messageInputRef.current.textContent = "";
    this.playAudio();

    this.closeEditPreview();

    XMPP.editMessage(textMessage)
      .then((message) => {
        this.props.actionGenerated("messageEdited", { ...message });
      })
      .catch((error) => {
        console.log("Message editing failed with error:", error);
      });
  };

  closeEditPreview = () => {
    this.props.actionGenerated("clearEditPreview");
  };

  startTyping = (timer, metadata) => {
    let typingInterval = timer || 5000;

    //if typing indicator is disabled for chat wigdet in dashboard
    if (validateWidgetSettings(this.props.widgetsettings, "show_typing_indicators") === false) {
      return false;
    }

    if (this.isTyping) {
      return false;
    }
    XMPP.startTyping(this.props.chatWith);

    this.isTyping = setTimeout(() => {
      this.endTyping(null, null);
    }, typingInterval);
  };

  endTyping = (event, metadata) => {
    //fixing synthetic issue
    if (event) {
      event.persist();
    }

    //if typing indicator is disabled for chat wigdet in dashboard
    if (validateWidgetSettings(this.props.widgetsettings, "show_typing_indicators") === false) {
      return false;
    }
    XMPP.endTyping();
    clearTimeout(this.isTyping);
    this.isTyping = null;
  };

  toggleStickerPicker = () => {
    const stickerViewer = this.state.stickerViewer;
    this.setState({ stickerViewer: !stickerViewer, emojiViewer: false });
  };

  toggleEmojiPicker = () => {
    const emojiViewer = this.state.emojiViewer;
    this.setState({ emojiViewer: !emojiViewer, stickerViewer: false });
  };

  toggleCreatePoll = () => {
    const createPoll = this.state.createPoll;
    this.setState({ createPoll: !createPoll });
  };

  toggleCollaborativeDocument = () => {
    const { receiverId, receiverType } = this.getReceiverDetails();

    XMPP.callExtension("document", "POST", "v1/create", {
      receiver: receiverId,
      receiverType: receiverType,
    })
      .then((response) => {
        // Response with document url
      })
      .catch((error) => {
        // Some error occured
      });
  };

  toggleCollaborativeBoard = () => {
    const { receiverId, receiverType } = this.getReceiverDetails();

    XMPP.callExtension("whiteboard", "POST", "v1/create", {
      receiver: receiverId,
      receiverType: receiverType,
    })
      .then((response) => {
        // Response with board_url
      })
      .catch((error) => {
        // Some error occured
      });
  };

  closeCreatePoll = () => {
    this.toggleCreatePoll();
    this.toggleFilePicker();
  };

  actionHandler = (action, message) => {
    switch (action) {
      case enums.ACTIONS["POLL_CREATED"]:
        this.toggleCreatePoll();
        this.toggleFilePicker();
        break;
      case "sendSticker":
        this.sendSticker(message);
        break;
      case "closeSticker":
        this.toggleStickerPicker();
        break;
      default:
        break;
    }
  };

  sendSticker = (stickerMessage) => {
    const { receiverId, receiverType } = this.getReceiverDetails();

    const customData = {
      sticker_url: stickerMessage.stickerUrl,
      sticker_name: stickerMessage.stickerName,
    };
    const customType = enums.CUSTOM_TYPE_STICKER;

    const conversationId = this.getConversationId();

    const customMessage = new XMPP.CustomMessage(receiverId, receiverType, customType, customData);
    customMessage.setMetadata(this.props.metaData);
    if (this.props.parentMessageId) {
      customMessage.setParentMessageId(this.props.parentMessageId);
    }
    customMessage.setSender(this.props.loggedInUser);
    customMessage.setReceiver(this.props.type);
    customMessage.setConversationId(conversationId);
    customMessage._composedAt = getUnixTimestamp();
    customMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [customMessage]);

    this.playAudio();

    XMPP.sendCustomMessage(customMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: customMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], newMessageObj);
      })
      .catch((error) => {
        console.log("custom message sending failed with error", error);

        const newMessageObj = { ...customMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], newMessageObj);
      });
  };

  sendReplyMessage = (messageInput) => {
    let { receiverId, receiverType } = this.getReceiverDetails();

    const conversationId = this.getConversationId();

    let textMessage = new XMPP.TextMessage(receiverId, messageInput, receiverType);
    if (this.props.parentMessageId) {
      textMessage.setParentMessageId(this.props.parentMessageId);
    }
    textMessage.setSender(this.props.loggedInUser);
    textMessage.setReceiver(this.props.type);
    textMessage.setConversationId(conversationId);
    textMessage._composedAt = getUnixTimestamp();
    textMessage._id = ID();

    this.props.actionGenerated(enums.ACTIONS["MESSAGE_COMPOSED"], [textMessage]);

    this.playAudio();
    this.setState({ replyPreview: null });

    XMPP.sendMessage(textMessage)
      .then((message) => {
        const newMessageObj = { ...message, _id: textMessage._id };
        this.props.actionGenerated(enums.ACTIONS["MESSAGE_SENT"], newMessageObj);
      })
      .catch((error) => {
        console.log("Message sending failed with error:", error);
        const newMessageObj = { ...textMessage, error: error };
        this.props.actionGenerated(enums.ACTIONS["ERROR_IN_SENDING_MESSAGE"], newMessageObj);
      });
  };

  clearReplyPreview = () => {
    this.setState({ replyPreview: null });
  };

  sendReaction = (event) => {
    const typingInterval = 1000;

    const typingMetadata = {
      type: enums.LIVE_REACTION_KEY,
      reaction: this.props.reaction,
    };

    this.startTyping(typingInterval, typingMetadata);
    this.props.actionGenerated("sendReaction");

    event.persist();

    setTimeout(() => {
      this.endTyping(null, typingMetadata);
      this.props.actionGenerated("stopReaction");
    }, typingInterval);
  };

  reactToMessages = (emoji) => {
    //const message = this.state.messageToReact;
    XMPP.callExtension("reactions", "POST", "v1/react", {
      msgId: this.state.messageToReact.id,
      emoji: emoji.colons,
    })
      .then((response) => {
        if (response.hasOwnProperty("success") && response["success"] === true) {
          this.toggleEmojiPicker();
        }
      })
      .catch((error) => {
        // Some error occured
      });
  };

  render() {
    let disabledState = false;

    const docText = Translator.translate("ATTACH_FILE", this.props.lang);
    let docs = (
      <div
        title={docText}
        css={fileItemStyle()}
        className='filelist__item item__file'
        onClick={() => {
          this.openFileDialogue("file");
        }}
      >
        <img src={docIcon} alt={docText} />
        <input onChange={this.onFileChange} type='file' id='file' ref={this.fileUploaderRef} />
      </div>
    );

    const videoText = Translator.translate("ATTACH_VIDEO", this.props.lang);
    const audioText = Translator.translate("ATTACH_AUDIO", this.props.lang);
    const imageText = Translator.translate("ATTACH_IMAGE", this.props.lang);
    let avp = (
      <React.Fragment>
        <div
          title={videoText}
          css={fileItemStyle()}
          className='filelist__item item__video'
          onClick={() => {
            this.openFileDialogue("video");
          }}
        >
          <img src={videoIcon} alt={videoText} />
          <input
            onChange={this.onVideoChange}
            accept='video/*'
            type='file'
            ref={this.videoUploaderRef}
          />
        </div>
        <div
          title={audioText}
          css={fileItemStyle()}
          className='filelist__item item__audio'
          onClick={() => {
            this.openFileDialogue("audio");
          }}
        >
          <img src={audioIcon} alt={audioText} />
          <input
            onChange={this.onAudioChange}
            accept='audio/*'
            type='file'
            ref={this.audioUploaderRef}
          />
        </div>
        <div
          title={imageText}
          css={fileItemStyle()}
          className='filelist__item item__image'
          onClick={() => {
            this.openFileDialogue("image");
          }}
        >
          <img src={imageIcon} alt={imageText} />
          <input
            onChange={this.onImageChange}
            accept='image/*'
            type='file'
            ref={this.imageUploaderRef}
          />
        </div>
      </React.Fragment>
    );

    const emojiText = Translator.translate("EMOJI", this.props.lang);
    let emojiBtn = (
      <div
        title={emojiText}
        css={emojiButtonStyle()}
        className='button__emoji'
        onClick={() => {
          this.toggleEmojiPicker();
          this.setState({ messageToReact: "" });
        }}
      >
        <img src={insertEmoticon} alt={emojiText} />
      </div>
    );

    const sendMessageText = Translator.translate("SEND_MESSAGE", this.props.lang);
    let sendBtn = (
      <div
        title={sendMessageText}
        css={sendButtonStyle()}
        className='button__send'
        onClick={this.sendTextMessage}
      >
        <img src={sendBlue} alt={sendMessageText} />
      </div>
    );

    //if photos, videos upload are disabled for chat widget in dashboard
    if (validateWidgetSettings(this.props.widgetsettings, "send_photos_videos") === false) {
      avp = null;
    }

    //if files upload are disabled for chat widget in dashboard
    if (validateWidgetSettings(this.props.widgetsettings, "send_files") === false) {
      docs = null;
    }

    //if emojis are disabled for chat widget in dashboard
    if (validateWidgetSettings(this.props.widgetsettings, "send_emojis") === false) {
      emojiBtn = null;
    }

    if (!this.state.messageInput.length) {
      sendBtn = null;
    }

    const attachText = Translator.translate("ATTACH", this.props.lang);
    let attach = (
      <div css={stickyAttachmentStyle()} className='input__sticky__attachment'>
        <div
          css={stickyAttachButtonStyle()}
          className='attachment__icon'
          onClick={this.toggleFilePicker}
          title={attachText}
        >
          <img src={roundedPlus} alt={attachText} />
        </div>
        <div
          css={filePickerStyle(this.state)}
          className='attachment__filepicker'
          dir={Translator.getDirection(this.props.lang)}
        >
          <div css={fileListStyle()} className='filepicker__filelist'>
            {avp}
            {docs}
          </div>
        </div>
      </div>
    );

    if (avp === null && docs === null) {
      attach = null;
    }

    let editPreview = null;
    if (this.state.messageToBeEdited) {
      let messageText = this.state.messageToBeEdited.text;

      //xss extensions data
      const xssData = checkMessageForExtensionsData(this.state.messageToBeEdited, "xss-filter");
      if (
        xssData &&
        xssData.hasOwnProperty("sanitized_text") &&
        xssData.hasOwnProperty("hasXSS") &&
        xssData.hasXSS === "yes"
      ) {
        messageText = xssData.sanitized_text;
      }

      //datamasking extensions data
      const maskedData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        "data-masking"
      );
      if (
        maskedData &&
        maskedData.hasOwnProperty("data") &&
        maskedData.data.hasOwnProperty("sensitive_data") &&
        maskedData.data.hasOwnProperty("message_masked") &&
        maskedData.data.sensitive_data === "yes"
      ) {
        messageText = maskedData.data.message_masked;
      }

      //profanity extensions data
      const profaneData = checkMessageForExtensionsData(
        this.state.messageToBeEdited,
        "profanity-filter"
      );
      if (
        profaneData &&
        profaneData.hasOwnProperty("profanity") &&
        profaneData.hasOwnProperty("message_clean") &&
        profaneData.profanity === "yes"
      ) {
        messageText = profaneData.message_clean;
      }

      editPreview = (
        <div css={editPreviewContainerStyle(this.props, keyframes)}>
          <div css={previewHeadingStyle()}>
            <div css={previewTextStyle()}>
              {Translator.translate("EDIT_MESSAGE", this.props.lang)}
            </div>
            <span css={previewCloseStyle(closeIcon)} onClick={this.closeEditPreview}></span>
          </div>
          <div>{messageText}</div>
        </div>
      );
    }

    let emojiViewer = null;
    if (this.state.emojiViewer) {
      emojiViewer = <ChatEmojiKeyboard lang={this.props.lang} emojiClicked={this.emojiClicked} />;
    }

    return (
      <div css={chatComposerStyle(this.props)} className='chat__composer w-full'>
        {editPreview}
        {emojiViewer}

        <div css={composerInputStyle()} className='composer__input'>
          <div tabIndex='-1' css={inputInnerStyle(this.props, this.state)} className='input__inner'>
            <div
              css={messageInputStyle(disabledState)}
              className='input__message-input'
              contentEditable='true'
              placeholder={Translator.translate("ENTER_YOUR_MESSAGE_HERE", this.props.lang)}
              dir={Translator.getDirection(this.props.lang)}
              onInput={this.changeHandler}
              onBlur={(event) => this.endTyping(event)}
              onKeyDown={this.sendMessageOnEnter}
              ref={this.messageInputRef}
            />
            <div css={inputStickyStyle(this.props, disabledState)} className='input__sticky'>
              {attach}
              <div
                css={stickyButtonStyle(this.props, this.state)}
                className='input__sticky__buttons'
              >
                {emojiBtn}
                {sendBtn}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Specifies the default values for props:
CustomComposer.defaultProps = {
  lang: "es",
  theme: theme,
};

CustomComposer.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
};

export default withApollo(CustomComposer);
