import React from "react";
import styles from "./style.module.scss";

import DoctorDefaultImage from "../assets/img/doctorDefaulImg.png";

const CallAnimation = ({ urlImage }) => {
  return (
    <div className={styles.callAnimationEasyMd}>
      <img
        className={styles.imgAvatar}
        src={urlImage || DoctorDefaultImage}
        alt='Doctor Avatar'
        width='100'
      />
    </div>
  );
};

CallAnimation.defaultProps = {
  urlImage: DoctorDefaultImage,
};

export default CallAnimation;
