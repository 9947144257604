export const verifyIdUserVsPatient = (idUser, idPatient) => {
  return idUser === idPatient;
};

export const verifyUsable = (status) => {
  /* PENDING
  SCHEDULED
  ONGOING
  COMPLETE
  CANCELED
  AVAILABLE */
  switch (status) {
    case "PENDING":
      return true;
    case "ONGOING":
      return true;
    default:
      return false;
  }
};

export const verifyFinish = (status) => {
  return status === "COMPLETE";
};

export const verifyRenderChat = (status) => {
  return status === "ONGOING";
};
