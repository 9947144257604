import React from "react";
// antd
import Form from "antd/lib/form";
// components
import InputWordCloud from "components/InputWordCloud";
// gql
import { getSymptoms } from "views/Patient/gql";

export default function FormSymptoms({ disabled, ...props }) {
  const input = {
    disabled,
    gql: getSymptoms,
    path: "symptoms.docs",
    emptyText: "Sin Síntomas",
    placeholder: "Síntomas",
  };

  return (
    <Form {...props}>
      <Form.Item name='newSymptoms'>
        <InputWordCloud {...input} />
      </Form.Item>
    </Form>
  );
}
