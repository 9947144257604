import React, { useState, cloneElement } from "react";
// antd
import Modal from "antd/lib/modal";

export default function HocDisplayImg({ file, children }) {
  const { name, url } = file;
  const [visible, setVisible] = useState(false);

  const onClick = () => setVisible(!visible);

  const props = {
    Modal: {
      visible,
      title: name,
      footer: null,
      center: true,
      onCancel: onClick,
    },
  };

  return (
    <>
      {cloneElement(children, { onClick })}
      <Modal {...props.Modal}>
        <img src={url} alt={`Image_${name}`} width={470} />
      </Modal>
    </>
  );
}
