import dotenv from "dotenv";
dotenv.config();

export const HOST = window.location.host;

export const LOCAL_HOST = "localhost:3000";
export const DEFAULT_VIEW_USER = "/admin/dashboard";
export const DEFAULT_VIEW_ADMIN = "/md/home";
export const {
  REACT_APP_NODE_ENV: NODE_ENV,
  REACT_APP_COMPANY_ID: COMPANY,
  REACT_APP_NATIONALITY: NATIONALITY,
  REACT_APP_COMPANY_LOGO: COMPANY_LOGO,
  REACT_APP_COMPANY_NAME: COMPANY_NAME,
  REACT_APP_LANDING_URL: LANDING_URL,
  REACT_APP_APOLLO_URI: URI,
  REACT_APP_ONE_CLICK_APPOINTMENT_REASON: ONE_CLICK_APPOINTMENT_REASON,
} = process.env;

export const IMMEDIATE_APPOINTMENT = process.env.REACT_APP_IMMEDIATE_APPOINTMENT === "true" && true;
