import React from "react";
import PropTypes from "prop-types";

import Translator from "../resources/localization/translator";
import { theme } from "../resources/theme";

import blueFile from "./resources/senderfile.png";
import { Spin } from "antd";

class SenderFileMessageBubble extends React.Component {
  messageFrom = "sender";

  constructor(props) {
    super(props);

    const message = Object.assign({}, props.message, {
      messageFrom: this.messageFrom,
    });

    this.state = {
      message: message,
      isHovering: false,
    };
  }

  componentDidUpdate(prevProps) {
    const previousMessageStr = JSON.stringify(prevProps.message);
    const currentMessageStr = JSON.stringify(this.props.message);

    if (previousMessageStr !== currentMessageStr) {
      const message = Object.assign({}, this.props.message, {
        messageFrom: this.messageFrom,
      });
      this.setState({ message: message });
    }
  }

  handleMouseHover = () => {
    this.setState(this.toggleHoverState);
  };

  toggleHoverState = (state) => {
    return {
      isHovering: !state.isHovering,
    };
  };

  render() {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "150px",
          height: "100px",
          borderRadius: 10,
        }}
      >
        <div style={{ cursor: "pointer", paddingTop: "10px" }}>
          <a
            href={this.state.message.file}
            target='_blank'
            rel='noopener noreferrer'
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img src={blueFile} alt='file' />
            {this.props.download && <p style={{ color: "#fff" }}>{"Descargar archivo"}</p>}
          </a>
        </div>
        {!this.props.download && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgba(0,0,0,0.5)",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              borderRadius: 8,
              top: 0,
              left: 0,
            }}
          >
            <Spin />
            <p style={{ color: "#fff" }}>{"enviando..."}</p>
          </div>
        )}
      </div>
    );
  }
}

// Specifies the default values for props:
SenderFileMessageBubble.defaultProps = {
  lang: Translator.getDefaultLanguage(),
  theme: theme,
};

SenderFileMessageBubble.propTypes = {
  lang: PropTypes.string,
  theme: PropTypes.object,
};

export default SenderFileMessageBubble;
