import React from "react";
// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkedAlt, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function ContactInfo() {
  return (
    <div style={{ maxWidth: 480 }}>
      
      { process.env.REACT_APP_COMPANY_ADDRESS && 
      <div className='flex mb-4'>
        <FontAwesomeIcon className='mr-4 mt-1 text-green' icon={faMapMarkedAlt} />
        <a
          className=' text-white'
          target='_blank'
          rel='noopener noreferrer'
          href='https://g.page/OficentroMetropolitano?share'
        >
          { process.env.REACT_APP_COMPANY_ADDRESS ?? '' }
        </a>
      </div>
      }

      { process.env.REACT_APP_COMPANY_PHONE && 
      <div className='flex mb-4'>
        <FontAwesomeIcon className='mr-4 mt-1 text-green' icon={faPhone} />
        <div className='flex flex-col'>
          <a className='text-white mb-2' href={`tel:${process.env.REACT_APP_COMPANY_PHONE}`}>
          { process.env.REACT_APP_COMPANY_PHONE ?? ''}
          </a>                
        </div>
      </div>
      }

      { process.env.REACT_APP_COMPANY_EMAIL && 
      <div className='flex'>
        <FontAwesomeIcon className='mr-4 mt-1 text-green' icon={faEnvelope} />
        <a className='text-white' href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`}>
          { process.env.REACT_APP_COMPANY_EMAIL }
        </a>
      </div>
      }
    </div>
  );
}
