import React from "react";
import Lottie from "react-lottie";
import CitaMedicaNoEncontrada from "../../../assets/lottie/citaNoEncontrada.json";

const ErrorCita = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CitaMedicaNoEncontrada,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      isClickToPauseDisabled
      options={defaultOptions}
      height={"80%"}
      width={"40%"}
      style={props.styles}
    />
  );
};

export default ErrorCita;
