import React, { useContext } from "react";
import QueueAnim from "rc-queue-anim";
import { useQuery } from "@apollo/react-hooks";
// components
import Card from "./Card";
import Loading from "layouts/profile/Loading";
import WithOutAppointment from "../../WithOutAppointment";
// context
import Context from "views/Patient/Context";
import { GlobalContext } from "views/App";
// gql
import { getMeAppointmentsDetail } from "views/Patient/gql";
// helpers
import map from "lodash/map";
import get from "lodash/get";
// styles
import style from "../../style.module.scss";

const props = {
  Queue: {
    key: "tabs",
    type: "bottom",
    className: "p-5",
    component: "section",
  },
};

export default function Appointments() {
  const { state } = useContext(GlobalContext);
  const { appointment } = useContext(Context);
  const variables = { id: state.user.id };

  const { data, loading } = useQuery(getMeAppointmentsDetail, {
    variables,
    fetchPolicy: "no-cache",
  });

  const source = get(data, "appointments.docs", []);

  if (loading) return <Loading />;

  return source.length ? (
    <QueueAnim {...props.Queue}>
      {map(source, ({ id, ...info }) => {
        const selected = appointment === id;
        const props = { key: id, ...info, id, selected };
        return <Card {...props} />;
      })}
    </QueueAnim>
  ) : (
    <div className={style.GridCenter}>
      <WithOutAppointment />
    </div>
  );
}
