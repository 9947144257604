import React from "react";
import moment from "moment";
// antd
import DatePicker from "antd/lib/date-picker";

export default function InputDayPicker(props) {
  const { prefix, value: val, ...attr } = props;

  const value = moment(val).isValid() ? moment(val) : moment();  
  const propsDatePicker = {
    ...attr,
    value,
    style: {
      width: "100%",
      border: 0,
      padding: 0,
      outline: "none",
    },
  };

  return (
    <div className='ant-form-item-control-input'>
      <div className='ant-form-item-control-input-content'>
        <span className='ant-input-affix-wrapper'>
          <span className='ant-input-prefix'>{prefix}</span>
          <DatePicker {...propsDatePicker} />
        </span>
      </div>
    </div>
  );
}
