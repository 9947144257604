import React from "react";
// antd
import Form from "antd/lib/form";
import Space from "antd/lib/space";
// inputs definitions
import input from "./inputs/condition";

const propsSpace = {
  size: 0,
  style: {
    display: "grid",
    gridGap: 24,
    alignItems: "start",
    gridTemplateColumns: "repeat(auto-fit, minmax(225px,1fr))",
  },
};

export default function FormCondition(props) {
  return (
    <Form {...props}>
      <Space {...propsSpace}>
        <Form.Item {...input.conditions} />
        <Form.Item {...input.allergies} />
      </Space>
    </Form>
  );
}
