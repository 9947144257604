import React from "react";
// antd
import Form from "antd/lib/form";
// inputs
import inputs from "./inputs";

export default function FormAttach(props) {
  return (
    <Form {...props}>
      <Form.Item {...inputs.name} />
      <Form.Item {...inputs.file} />
    </Form>
  );
}
