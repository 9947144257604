import React from "react";
// antd
// import Badge from 'antd/lib/badge'
// icons
import { FontAwesomeIcon as Fa } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faList, faHome } from "@fortawesome/free-solid-svg-icons";

export default [
  {
    key: "profile",
    role: ["doctor", "patient", "doctorMD"],
    icon: <Fa icon={faHome} />,
    children: <span className='ml-3'>Mi Perfil</span>,
  },
  {
    key: "appointment",
    role: ["doctor", "patient"],
    icon: <Fa icon={faList} />,
    children: <span className='ml-3 pr-8'>Mis Consultas</span>,
  },
  {
    key: "dashboard",
    role: ["root", "company", "operationsRole"],
    icon: <Fa icon={faHome} />,
    children: <span className='ml-3 pr-8'>Dashboard</span>,
  },
  {
    key: "logout",
    role: ["root", "doctor", "patient", "doctorMD", "company", "operationsRole"],
    icon: <Fa icon={faSignOutAlt} />,
    children: <span className='ml-3'>Salir</span>,
    className: "text-danger",
  },
];
