import React, { useContext, Fragment } from "react";
// antd
import Typography from "antd/lib/typography";
// components
import Title from "./Title";
import Medic from "./Medic";
// context
import Context from "./Context";
// helper
import map from "lodash/map";
import join from "lodash/join";

const P = Typography.Paragraph;

export default function General() {
  const { reason, patient, medic } = useContext(Context);

  const props = {
    P: {
      ellipsis: {
        row: 3,
        expandable: true,
      },
      children: reason,
    },
    Medic: {
      pic: medic?.pic.url,
      name: medic?.name,
    },
  };

  return (
    <Fragment>
      <Title>Razón de la Cita</Title>
      <P {...props.P} />
      <Title level={4}>Paciente</Title>
      <p>{patient.name}</p>
      {medic && (
        <Fragment>
          <Title level={4}>Médico</Title>
          <Medic {...props.Medic} />
          {join(map(medic.specialties, "name"), ", ")}
        </Fragment>
      )}
    </Fragment>
  );
}
