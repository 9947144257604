import React from "react";
import { Link } from "react-router-dom";

const LogoUrl= 'https://wl-asssets.s3.us-east-1.amazonaws.com/' + process.env.REACT_APP_COMPANY_LOGO;

export default function Brand() {
  return (
    <Link to='/md/home' className='brand'>
      <img
        style={{
          width: "240px",
          height: "50px",
          objectFit: "contain",
        }}
        src={LogoUrl}
        alt='Logo'
      />
    </Link>
  );
}
