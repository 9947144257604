import React from "react";
import Card from "antd/lib/card";
import styles from "../styles.module.scss";

// icons Custom
import doctorIcon from "assets/customIcons/consulta.svg";
import doctorColorIcon from "assets/customIcons/consulta-hover.svg";
import brainIcon from "assets/customIcons/Psicologia.svg";
import brainColorIcon from "assets/customIcons/Psicologia-hover.svg";

const CardRadio = (props) => {
  const { focusButton, onClickCard, propsCard } = props;

  return (
    <div className='grid grid-cols-12 gap-0 w-8/12 mx-auto'>
      <div className='col-span-12 md:col-span-6 flex justify-center'>
        <Card
          bodyStyle={{ height: "100%" }}
          className={`w-10/12 ${styles.cardRadioMD} ${
            focusButton === propsCard[0].key && styles.cardRadioMD_active
          }`}
          title={null}
          bordered={false}
          onClick={() => onClickCard(propsCard[0].key)}
        >
          <div className='flex flex-col justify-center items-center h-full'>
            <img
              style={{ maxWidth: "5.31rem" }}
              className={`${focusButton !== propsCard[0].key && "hidden"}`}
              src={propsCard[0].iconSrc[1]}
              alt={`Icon_${propsCard[0].key}`}
            />
            <img
              style={{ maxWidth: "5.31rem" }}
              className={`${focusButton === propsCard[0].key && "hidden"}`}
              src={propsCard[0].iconSrc[0]}
              alt={`Icon_${propsCard[0].key}`}
            />
            <span
              className={`mt-2 text-center text-lg ${
                focusButton === propsCard[0].key && "text-primary"
              }`}
            >
              {propsCard[0].title}
            </span>
          </div>
        </Card>
      </div>
      <div className='col-span-12 md:col-span-6 flex justify-center'>
        <Card
          bodyStyle={{ height: "100%" }}
          className={`w-10/12 ${styles.cardRadioMD} ${
            focusButton === propsCard[1].key && styles.cardRadioMD_active
          }`}
          title={null}
          bordered={false}
          onClick={() => onClickCard(propsCard[1].key)}
        >
          <div className='flex flex-col justify-center items-center'>
            <img
              style={{ maxWidth: "5.31rem" }}
              className={`${focusButton !== propsCard[1].key && "hidden"}`}
              src={propsCard[1].iconSrc[1]}
              alt={`Icon_${propsCard[0].key}`}
            />
            <img
              style={{ maxWidth: "5.31rem" }}
              className={`${focusButton === propsCard[1].key && "hidden"}`}
              src={propsCard[1].iconSrc[0]}
              alt={`Icon_${propsCard[0].key}`}
            />
            <span
              className={`mt-2 text-center text-lg ${
                focusButton === propsCard[1].key && "text-primary"
              }`}
            >
              {propsCard[1].title}
            </span>
          </div>
        </Card>
      </div>
    </div>
  );
};

CardRadio.defaultProps = {
  focusButton: "GENERAL",
  propsCard: [
    {
      title: "Medicina General",
      iconSrc: [doctorIcon, doctorColorIcon],
      key: "GENERAL",
    },
    {
      title: "Psicología",
      iconSrc: [brainIcon, brainColorIcon],
      key: "PSYCHOLOGY",
    },
  ],
  onClickCard: () => console.log("sumit_onClickCard"),
};

export default CardRadio;
