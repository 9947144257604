import isArray from "lodash/isArray";

export const HandleError = (graphQLErrors) => {
  if (isArray(graphQLErrors.graphQLErrors)) {
    if (graphQLErrors.graphQLErrors.length) {
      return graphQLErrors.graphQLErrors[0].message;
    }

    console.log(JSON.stringify(graphQLErrors, null, 2));
    return "Ha ocurrido un ocurrido un error al procesar su información, por favor inténtelo más tarde ";
  }
  console.log(JSON.stringify(graphQLErrors, null, 2));
  return "Ha ocurrido un ocurrido un error al procesar su información, por favor inténtelo más tarde ";
};
