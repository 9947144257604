import React, { cloneElement } from "react";
// ant
import Modal from "antd/lib/modal";

// componets
import ContendTermsAndConditions from "components/ModalTermsAndConditions/ContendTermsAndConditions";

const HoccModal = (_) => {
  const { children, onSucces, visibleCancel, ...attr } = _;
  const onOk = () => {
    onSucces();
    Modal.destroyAll();
  };

  const props = {
    onOk,
    icon: null,
    okButtonProps: {
      htmlType: "submit",
      shape: "round",
      key: "send",
    },
    okText: "Aceptar",
    cancelText: "Cerrar",
    cancelButtonProps: {
      shape: "round",
      key: "cancel",
    },
    closable: visibleCancel === false,
    width: "55rem",
    title: "TÉRMINOS Y CONDICIONES DEL SERVICIO DE TELEMEDICINA",
    content: (
      <div style={{ height: "65vh" }} className='mx-auto overflow-auto'>
        <ContendTermsAndConditions />
      </div>
    ),
  };

  const onClick = () => {
    if (visibleCancel) {
      Modal.confirm({
        ...props,
        ...attr,
      });
    } else {
      Modal.info({
        ...props,
        ...attr,
      });
    }
  };

  return cloneElement(children, { onClick });
};

HoccModal.defaultProps = {
  children: <p>Contend</p>,
  visibleCancel: true,
  onSucces: () => {},
};

export default HoccModal;
