import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Table, Input, notification } from "antd";

import { wizardContext } from "../ModalWizard/ContendModal";
import * as actionName from "../Store/actionStore";
import { patientListGql } from "../gqlWizard";

import columns from "../Component/columnsPacient";

const Steps1 = () => {
  const { state, dispatch } = useContext(wizardContext);
  const [paginationVars, setPaginationVars] = useState({
    perPage: 5,
    page: 1,
  });
  const [nombreBuscar, setNombreBuscar] = useState("");
  const [filterDoctor, setFilterDoctor] = useState([]);

  const { data, loading, error } = useQuery(patientListGql, {
    variables: {
      name: "Interconsulta",
      ...paginationVars,
      patientFilter: filterDoctor,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    const newFilter = [];
    if (nombreBuscar.length > 0) {
      newFilter.push({
        byText: {
          field: "FIRSTNAME",
          value: nombreBuscar,
          or: "LASTNAME",
        },
      });
    }
    setFilterDoctor(newFilter);
    setPaginationVars({
      perPage: 5,
      page: 1,
    });
  }, [nombreBuscar]);

  useEffect(() => {
    if (error && !loading) {
      notification.error({
        message: "Error",
        description: error.graphQLErrors[0]
          ? error.graphQLErrors[0].message
          : "Error al solicitar data",
      });
    }
  }, [error, loading]);

  const getDataForTable = () => {
    if (data) {
      return data.usersFromCompany?.docs;
    } else {
      return [];
    }
  };

  const getPagination = () => {
    return {
      current: paginationVars.page,
      pageSize: paginationVars.perPage,
      total: data ? data.usersFromCompany?.total : 0,
      showSizeChanger: false,
    };
  };

  const handleTableChange = (infoTable) => {
    const { current } = infoTable;
    setPaginationVars({
      ...paginationVars,
      page: current,
    });
  };

  const setPatient = async (payload) => {
    return [
      await dispatch({
        type: actionName.SET_PATIENT,
        payload: payload,
      }),
      dispatch({
        type: actionName.SET_STEPS,
        payload: state.stepsActive + 1,
      }),
    ];
  };

  return (
    <>
      <div className='flex justify-center w-full mt-10 mb-8'>
        <h3 style={{ color: "#707070" }} className='text-xl'>
          Tu consulta requiere a un paciente
        </h3>
      </div>
      <div className='w-full flex xs:flex-col sm:flex-row mb-3'>
        <Input
          className={"w-full mx-auto sm:mx-0 "}
          placeholder='Buscar por nombre'
          value={nombreBuscar}
          onChange={(e) => setNombreBuscar(e.target.value)}
        />
      </div>
      <Table
        rowSelection={{
          type: "radio",
          onSelect: (selected) => setPatient(selected),
        }}
        columns={columns(loading)}
        rowKey={(record) => record.id}
        dataSource={getDataForTable()}
        pagination={getPagination()}
        loading={loading}
        onChange={handleTableChange}
      />{" "}
    </>
  );
};

export default Steps1;
