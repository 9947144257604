import gql from "graphql-tag";
export const sendNotificationNewMessageGql = gql`
  query sendNotificationNewMessage($id: String!, $message: String) {
    sendNotificationNewMessage(id: $id, message: $message)
  }
`;

export const uploadFileGql = gql`
  mutation uploadFile($file: Upload!, $folder: bucketFoders!, $acl: acl!) {
    uploadFile(file: $file, folder: $folder, acl: $acl) {
      id
      url
      mimetype
    }
  }
`;
