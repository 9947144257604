import React from "react";
// antd
import Card from "antd/lib/card";
import Space from "antd/lib/space";
// components
import Trace from "./Trace";
import General from "./General";
import Attachment from "./Attachment";
import BadgeStatus from "./Badge";
// context
import Context from "./Context";
// styles
import style from "./style.module.scss";

export default function AppointmentCard(_) {
  const { status, usable, type } = _;
  const isMD = type.includes("MD");
  const props = {
    Card: {
      extra: !isMD && <BadgeStatus status={status} />,
      className: style.card,
      bodyStyle: isMD ? {} : { paddingTop: 0 },
    },
    Space: { size: 0 },
    Context: {
      value: {
        ..._,
        isMD,
        editable: status === "SCHEDULED" && usable !== "AFTER_APPOINTMENT",
      },
    },
  };

  return (
    <Context.Provider {...props.Context}>
      <Card {...props.Card}>
        <Space {...props.Space}>
          <General />
          <Attachment />
          <Trace />
        </Space>
      </Card>
    </Context.Provider>
  );
}
