import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import LoadingLottie from "../LoadingLottie";
import UseLogin from "../UseLogin";
import FormLogin from "../FormLogin";
import FormSignUp from "../FormCrearCuenta";
import { useHistory } from "react-router-dom";

import { notification, Card } from "antd";

const loginGql = () => {
  return gql`
    query login($email: String!, $password: String!, $phoneNumber: String) {
      login(email: $email, password: $password, phoneNumber: $phoneNumber) {
        token
        refreshToken
      }
    }
  `;
};

const createPatient = gql`
  mutation createPatient(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $firstName: String!
    $lastName: String!
  ) {
    signupPatient(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      firstName: $firstName
      lastName: $lastName
    ) {
      id
      fullName
    }
  }
`;

const VerifyLoginWrapper = (props) => {
  const history = useHistory();
  const [renderSignUp, setRenderSignUp] = useState(props.forceSignUp);
  const [fetchLogin, { loading: loadingLogin, error: errorLogin, data: dataLogin }] = useLazyQuery(
    loginGql(),
    { fetchPolicy: "no-cache" }
  );
  const [fetchCreatePatient, { loading: loadingSignUp, error: errorSignUp, data: dataSignUp }] =
    useMutation(createPatient, { fetchPolicy: "no-cache" });
  const { loading: loadingUseLogin, isLogin, roleUser, roleGroup, refetchUser } = UseLogin(true);

  useEffect(() => {
    if (!loadingLogin) {
      if (dataLogin) {
        localStorage.setItem("token", dataLogin.login.token);
        localStorage.setItem("refreshToken", dataLogin.login.refreshToken);
        refetchUser();
      }
    }
  }, [loadingLogin, errorLogin, dataLogin, refetchUser]);

  useEffect(() => {
    if (!loadingSignUp) {
      if (dataSignUp) {
        omitSignUp();
      }
    }
  }, [loadingSignUp, errorSignUp, dataSignUp]);

  const onFinishLogin = (values) => {
    fetchLogin({ variables: values });
  };

  const onFinishSignUp = async (values) => {
    const { email, password, confirmPassword, firstName, lastName } = values;
    try {
      await fetchCreatePatient({
        variables: { email, password, confirmPassword, firstName, lastName },
      });
    } catch (error) {
      notification.error({
        message: "Internal server error ",
        description: "",
      });
    }
  };

  const omitSignUp = () => {
    setRenderSignUp(false);
  };

  if (isLogin && props.role) {
    if (Array.isArray(props.role) && !props.role.includes(roleGroup)) {
      history.push({ pathname: "/" });
      return null;
    }
    if (typeof props.role === "string" && props.role !== roleUser) {
      history.push({ pathname: "/" });
      return null;
    }
  }

  const containerClass = props.fullScreen
    ? "mx-auto w-2/3 md:w-3/5 md:h-screen py-5 " + (renderSignUp ? " lg:w-2/4 " : " lg:w-1/4 ")
    : "w-full h-full";

  if (loadingUseLogin || !isLogin) {
    return (
      <>
        {loadingUseLogin ? (
          <div
            className={` ${
              props.fullScreen
                ? "flex h-screen py-5 justify-center"
                : "flex py-5 justify-center w-full h-24"
            } `}
          >
            <div className='self-center'>{props.loadingNode}</div>
          </div>
        ) : (
          !isLogin && (
            <div className={containerClass}>
              <Card className='shadow-md rounded-xl'>
                {props.forceSignUp && renderSignUp ? (
                  <FormSignUp
                    clickLoginButton={omitSignUp}
                    onFinish={onFinishSignUp}
                    loading={loadingSignUp}
                    data={dataSignUp}
                    error={errorSignUp}
                    redirect={false}
                    buttonLogin={props.forceSignUp}
                    title='Bienvenido'
                    description='Conectate con tus proveedores de salud en menos de 5 min estes donde estes.'
                  />
                ) : (
                  <FormLogin
                    alertError
                    loading={loadingLogin}
                    error={errorLogin}
                    onFinishForm={onFinishLogin}
                    visibleButtonCreateAccount={false}
                    title='Bienvenidos'
                    description='Ahora sos parte del futuro de la salud.'
                  />
                )}
              </Card>
            </div>
          )
        )}
      </>
    );
  }

  return <>{props.children}</>;
};

VerifyLoginWrapper.defaultProps = {
  forceSignUp: true,
  loadingNode: <LoadingLottie />,
  role: null,
  fullScreen: true,
};

export default VerifyLoginWrapper;
