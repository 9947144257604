import React from "react";
import Typography from "antd/lib/typography";

export default function Title(props) {
  const propsTitle = {
    level: 4,
    className: "text-primary",
    ...props,
  };

  return <Typography.Title {...propsTitle} />;
}
