import { client, xml } from "@xmpp/client";
const {
  REACT_APP_OPENFIRE_SERVICE_DEV,
  REACT_APP_OPENFIRE_DOMAIN_DEV,
  REACT_APP_OPENFIRE_RESOURCE_DEV,
  REACT_APP_OPENFIRE_SERVICE_PROD,
  REACT_APP_OPENFIRE_DOMAIN_PROD,
  REACT_APP_OPENFIRE_RESOURCE_PROD,
  REACT_APP_NODE_ENV,
} = process.env;

const NODE_ENV = REACT_APP_NODE_ENV || "development";
var service, domain, resource;
if (NODE_ENV === "production") {
  service = REACT_APP_OPENFIRE_SERVICE_PROD;
  domain = REACT_APP_OPENFIRE_DOMAIN_PROD;
  resource = REACT_APP_OPENFIRE_RESOURCE_PROD;
} else {
  service = REACT_APP_OPENFIRE_SERVICE_DEV;
  domain = REACT_APP_OPENFIRE_DOMAIN_DEV;
  resource = REACT_APP_OPENFIRE_RESOURCE_DEV;
}

// debugging
const debug = require("@xmpp/debug");

// XMPP module
const XMPP = {};

// xmpp client
var xmpp = null;

// logged in user
var user = null;

// XMPP types
XMPP.CALL_STATUS = {
  REJECTED: "REJECTED",
};
XMPP.CALL_TYPE = {
  VIDEO: "VIDEO",
};
XMPP.RECEIVER_TYPE = {
  USER: "USER",
  GROUP: "GROUP",
};
XMPP.MESSAGE_TYPE = {
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  FILE: "FILE",
  AUDIO: "AUDIO",
  VIDEO: "VIDEO",
};
XMPP.CATEGORY_MESSAGE = "CATEGORY_MESSAGE";

const listeners = {};

const CALL_PING_TIME = 1000;
const CALL_TIME_OUT = 60000;

var TIME_INTERVAL_CALLING;
var TIMEOUT_CALL;
var TYPING_TIME_OUT = null;

function clearTypingIndicator() {
  if (TYPING_TIME_OUT) {
    clearTimeout(TYPING_TIME_OUT);
    TYPING_TIME_OUT = null;
  }
}

// Get xmpp login user
XMPP.getLoggedinUser = () => {
  return user;
};

// Logout from xmpp client
XMPP.logout = async () => {
  // Log out
  if (xmpp) {
    await xmpp.stop();
    xmpp = null;
  }
};

// Inititlize the xmpp client
XMPP.init = async (username, password, onPresence, onConflict, loginResource = null) => {
  if (!xmpp) {
    xmpp = client({
      service,
      domain,
      resource: loginResource || resource,
      username,
      password,
    });
    console.log("xmpp login");
    debug(xmpp, true);
    xmpp.on("error", (err) => {
      console.error(err);
      if (err.message === "conflict") {
        xmpp.stop();
        onConflict();
        xmpp = null;
      }
    });

    xmpp.on("offline", () => {
      xmpp = null;
    });
    xmpp.on("online", (address) => {
      console.log("Agent Started", address);
      xmpp.send(xml("presence", {}, "online"));
      user = address;
      onPresence({ [user._local]: "online" });
      onPresence({
        [`${user._local}@${user._domain}/${user._resource}`]: "online",
      });
    });
    console.log("Registering stanza 3");
    xmpp.on("stanza", async (stanza) => {
      const { to, from } = stanza.attrs;
      if (stanza.is("presence") && to !== from) {
        const { type } = stanza.attrs;
        if (type === "subscribe") {
          xmpp.send(
            xml("presence", {
              to: from,
              type: "subscribed",
            })
          );
        } else {
          const status = stanza.text();
          const presence = type === "unavailable" ? "offline" : status;
          if (from) onPresence({ [from]: presence });
        }
      }
    });
    xmpp.start().catch(console.error);
  }
};

// Message Listener
class MessageListener {
  constructor({ onTextMessageReceived, onMediaMessageReceived, onCustomMessageReceived }) {
    if (xmpp) {
      xmpp.on("stanza", (stanza) => {
        try {
          if (stanza.is("message")) {
            var body = stanza.getChild("body");
            const { appointmentId } = body.attrs;
            if (appointmentId) {
              onTextMessageReceived(body.attrs);
            }
          }
        } catch (error) {}
      });
    }
  }
}

class CallListener {
  constructor({
    onIncomingCallReceived,
    onOutgoingCallAccepted,
    onOutgoingCallRejected,
    onBusyCall,
    onIncomingCallCancelled,
  }) {
    if (xmpp) {
      function onStanza(stanza) {
        if (stanza.is("iq")) {
          try {
            const ping = stanza.getChild("ping");
            const callStatus = ping.text();
            const metaData = ping.attrs;
            console.log(":CALL STATUS", callStatus);
            if (callStatus === "CALL_BUSY") {
              onBusyCall(metaData);
              clearInterval(TIME_INTERVAL_CALLING);
              clearTimeout(TIMEOUT_CALL);
            }
            if (callStatus === "CALL_INITIATED") {
              onIncomingCallReceived(metaData);
            }
            if (callStatus === "READY_TO_ACCEPT") {
              clearInterval(TIME_INTERVAL_CALLING);
            }
            if (callStatus === "CALL_ACCEPTED") {
              console.log("CLEARING TIMEOUT_CALL AND INTERVAL");
              clearInterval(TIME_INTERVAL_CALLING);
              clearTimeout(TIMEOUT_CALL);
              onOutgoingCallAccepted();
            }
            if (callStatus === "CALL_REJECTED") {
              onOutgoingCallRejected();
              clearInterval(TIME_INTERVAL_CALLING);
              clearTimeout(TIMEOUT_CALL);
            }
            if (callStatus === "CALL_DISCONNECT") {
              onOutgoingCallRejected();
              clearInterval(TIME_INTERVAL_CALLING);
              clearTimeout(TIMEOUT_CALL);
            }
          } catch (error) {}
        }
      }
      xmpp.on("stanza", onStanza);
    }
  }
}

XMPP.getFirstAndLastMessageId = (chatWith, onFetchMessages) => {
  async function onStanza(stanza) {
    if (stanza.is("iq") && stanza.attrs?.id === "firstAndLastMessageId") {
      try {
        const fin = stanza.getChild("fin");
        const set = fin.getChild("set");
        const _first = set.getChild("first");
        var first = null;
        if (_first) first = _first.text();
        onFetchMessages({ first });
        xmpp.removeListener("stanza", onStanza);
      } catch (error) {}
    }
  }
  console.log("Registering stanza 4");
  xmpp.on("stanza", onStanza);
  if (xmpp) {
    xmpp.send(
      xml(
        "iq",
        { type: "set", id: "firstAndLastMessageId" },
        xml("query", { xmlns: "urn:xmpp:mam:2" }, [
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "submit" },
            xml(
              "field",
              { var: "with" },
              xml("value", {}, getUsername(chatWith.username, true, chatWith?.appointmentId))
            )
          ),
          xml("set", { xmlns: "http://jabber.org/protocol/rsm" }, [
            xml("max", {}, "1"),
            xml("after", {}, null),
          ]),
        ])
      )
    );
  }
};
XMPP.fetchMessages = (before, me, chatWith, onFetchMessages, onNewMessage) => {
  const messages = [];
  if (!before) {
    function onStanza(stanza) {
      const { to, from } = stanza.attrs;
      const isChatWith = from === getUsername(chatWith.username, true, chatWith?.appointmentId);

      if (stanza.is("iq")) {
        try {
          const fin = stanza.getChild("fin");
          const set = fin.getChild("set");
          const count = set.getChild("count").text();
          if (stanza.attrs?.id === "firstAndLastMessageId") return;
          if (parseInt(count) === 0) return onFetchMessages(null);
          const first = set.getChild("first").text();
          const last = set.getChild("last").text();
          onFetchMessages({ first, last, count, messages });
        } catch (error) {}
      }

      if (stanza.is("message")) {
        try {
          var body = stanza.getChild("body");
          if (body && isChatWith) {
            var body = stanza.getChild("body");
            const { id: _id } = stanza.attrs;
            const { file_type, status } = body.attrs;
            const text = body.text();
            var message;
            if (file_type) {
              message = {
                _id,
                createdAt: new Date(),
                user: chatWith,
                file: text,
                file_type,
                status,
              };
            } else {
              message = {
                _id,
                text,
                createdAt: new Date(),
                user: chatWith,
              };
            }
            onNewMessage(message);
          } else {
            var result = stanza.getChild("result");
            var forwarded = result.getChild("forwarded");
            var delay = forwarded.getChild("delay");
            var message = forwarded.getChild("message");
            const { id } = result.attrs;
            var { stamp } = delay.attrs;
            var body = message.getChild("body");
            const { file_type, status } = body.attrs;
            const text = body.text();
            const { from, id: _id } = message.attrs;
            if (file_type) {
              messages.push({
                _id: _id || id,
                createdAt: new Date(stamp),
                user: from === to ? me : chatWith,
                file: text,
                file_type,
                status,
              });
            } else {
              messages.push({
                _id: _id || id,
                text,
                createdAt: new Date(stamp),
                user: from === to ? me : chatWith,
              });
            }
            messages.sort(function (a, b) {
              return b.createdAt - a.createdAt || 0;
            });
          }
        } catch (e) {}
      }
    }
    console.log("Registering stanza 2");
    xmpp.on("stanza", onStanza);
  }
  if (xmpp) {
    xmpp.send(
      xml(
        "iq",
        { type: "set" },
        xml("query", { xmlns: "urn:xmpp:mam:2" }, [
          xml(
            "x",
            { xmlns: "jabber:x:data", type: "submit" },
            xml(
              "field",
              { var: "with" },
              xml("value", {}, getUsername(chatWith.username, true, chatWith?.appointmentId))
            )
          ),
          xml("set", { xmlns: "http://jabber.org/protocol/rsm" }, [
            xml("max", {}, "20"),
            xml("before", {}, before),
          ]),
        ])
      )
    );
  }
};

class OngoingCallListener {
  constructor(onUserJoined, onUserLeft, onCallEnded) {
    if (xmpp) {
    }
  }
}

XMPP.addMessageListener = (listenerID, messageListener) => {
  listeners[listenerID] = messageListener;
};

XMPP.getLoggedInUser = () => {
  return user;
};

XMPP.subscribePresence = (username) => {
  xmpp.send(
    xml("presence", {
      to: getUsername(username, false),
      type: "subscribe",
    })
  );
};

XMPP.addCallListener = (listenerID, callListener) => {};

XMPP.removeCallListener = (listenerID) => {};

XMPP.removeMessageListener = (listenerID) => {};

XMPP.clearCache = () => {};

XMPP.endCall = async () => {};

XMPP.getActiveCall = async (sessionID) => {};

async function sendCallStatus(status, metaData) {
  console.log(
    getUsername(metaData?.chatWith, true, metaData?.id),
    getUsername(metaData?.chatWith, true, resource)
  );

  const iqs = [
    xml(
      "iq",
      { to: getUsername(metaData?.chatWith, true, metaData?.id), type: "get" },
      xml("ping", { xmlns: "urn:xmpp:ping", ...metaData }, status)
    ),
    xml(
      "iq",
      { to: getUsername(metaData?.chatWith, true, resource), type: "get" },
      xml("ping", { xmlns: "urn:xmpp:ping", ...metaData }, status)
    ),
  ];
  await xmpp.sendMany(iqs);
  const msg = xml(
    "message",
    {
      type: "chat",
      id: parseInt(Math.random() * 1000000),
      to: getUsername(metaData?.chatWith, true, metaData?.id),
    },
    xml("body", { appointmentId: metaData?.id, timestamp: new Date() }, status)
  );
  if (
    status === "CALL_INITIATED" ||
    status === "CALL_DISCONNECT" ||
    status === "CALL_REJECTED" ||
    status === "CALL_TIMEOUT"
  ) {
    await xmpp.send(msg);
  }
}

XMPP.readyToAccept = async (metaData) => {
  await sendCallStatus("READY_TO_ACCEPT", metaData);
};

XMPP.acceptCall = async (metaData) => {
  await sendCallStatus("CALL_ACCEPTED", metaData);
};

XMPP.rejectCall = async (metaData) => {
  await sendCallStatus("CALL_REJECTED", metaData);
  clearInterval(TIME_INTERVAL_CALLING);
  clearTimeout(TIMEOUT_CALL);
};
XMPP.disconnectCall = async (metaData) => {
  await sendCallStatus("CALL_DISCONNECT", metaData);
  clearInterval(TIME_INTERVAL_CALLING);
  clearTimeout(TIMEOUT_CALL);
};

XMPP.deleteMessage = (messageId) => {};

XMPP.mediaMessage = (username, mediaMessage, appointmentId) => {
  const msg = xml(
    "message",
    {
      type: "chat",
      to: getUsername(username, true, appointmentId),
      id: mediaMessage?._id,
      appointmentId,
    },
    xml(
      "body",
      { file_type: mediaMessage.file_type, status: mediaMessage.status },
      mediaMessage.file
    )
  );
  xmpp.send(msg);
};
XMPP.TextMessage = (receiverId, messageInput, messageType, receiverType) => {};
XMPP.sendTextMessage = async (username, message, appointmentId) => {
  if (xmpp.status === "online") {
    clearTypingIndicator();
    xmpp.send(xml("presence", {}, "online"));
    const msg = xml(
      "message",
      {
        type: "chat",
        to: getUsername(username, true, appointmentId),
        id: message?._id,
        appointmentId,
      },
      xml("body", {}, message?.text)
    );
    xmpp.send(msg);
  }
};
XMPP.editMessage = (receiverId, messageInput, messageType, receiverType) => {};

XMPP.startTyping = (from) => {
  if (!from) return;
  if (xmpp.status === "online") {
    xmpp.send(xml("presence", { from }, "typing..."));
    clearTypingIndicator();
    TYPING_TIME_OUT = setTimeout(() => {
      xmpp.send(xml("presence", {}, "online"));
    }, 2000);
  }
};
XMPP.endTyping = () => {
  if (xmpp.status === "online") {
    clearTypingIndicator();
    xmpp.send(xml("presence", {}, "online"));
  }
};
XMPP.CustomMessage = (receiverId, messageInput, messageType, receiverType) => {};

XMPP.sendCustomMessage = (receiverId, messageInput, messageType, receiverType) => {};

const getUsername = (username, withResource = true, loginResource = null) => {
  if (withResource) {
    return username + `@${domain}/${loginResource || resource}`;
  }
  return username + `@${domain}`;
};

XMPP.initiateCall = (metaData, onCallTimeOut) => {
  const usernames = [
    getUsername(metaData.chatWith, true, metaData?.id),
    getUsername(metaData.chatWith, true, resource),
  ];
  sendCallStatus("CALL_INITIATED", metaData);
  clearInterval(TIME_INTERVAL_CALLING);
  clearTimeout(TIMEOUT_CALL);
  TIME_INTERVAL_CALLING = setInterval(() => {
    const stanzas = usernames.map((username) =>
      xml(
        "iq",
        {
          to: username,
          type: "get",
        },
        xml("ping", { xmlns: "urn:xmpp:ping", ...metaData }, "CALL_INITIATED")
      )
    );
    xmpp.sendMany(stanzas).catch(console.error);
    console.log("CALLING", usernames);
  }, CALL_PING_TIME);

  console.log("REGISTERING CALL TIME OUT", TIMEOUT_CALL);
  TIMEOUT_CALL = setTimeout(() => {
    clearInterval(TIME_INTERVAL_CALLING);
    const stanzas = usernames.map((username) =>
      xml(
        "iq",
        {
          to: username,
          type: "get",
        },
        xml("ping", { xmlns: "urn:xmpp:ping" }, "CALL_TIMEOUT")
      )
    );
    sendCallStatus("CALL_TIMEOUT", metaData);
    console.log("CALL TIME OUT");
    xmpp.sendMany(stanzas).catch(console.error);
    onCallTimeOut();
  }, CALL_TIME_OUT);
};

XMPP.MessageListener = MessageListener;
XMPP.CallListener = CallListener;
XMPP.OngoingCallListener = OngoingCallListener;

export { XMPP, getUsername };
