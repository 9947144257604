import React, { useState, useEffect } from "react";
import BrandLoading from "../../../components/CitasUtils/BrandLoading";
import { Layout } from "antd";

import ContainerSala from "./containerSala";
const { Content } = Layout;

const getIdAppointment = (props) => {
  let info = {
    idAppointment: null,
    sessionID: null,
  };
  const { location, match } = props;
  if (location) {
    const { state } = location;
    if (state) {
      const { idAppointment, sessionID } = state;
      info = {
        idAppointment: idAppointment,
        sessionID: sessionID,
      };
    } else {
      if (match) {
        const { params } = match;
        if (params) {
          info = {
            idAppointment: params.id,
            sessionID: info ? (info.sessionID ? info.sessionID : null) : null,
          };
        }
      }
    }
  } else {
    if (match) {
      const { params } = match;
      if (params) {
        info = {
          idAppointment: params.id,
          sessionID: info ? (info.sessionID ? info.sessionID : null) : null,
        };
      }
    }
  }
  return info;
};

const PacienteMD = (props) => {
  const [infoSession, setInfoSession] = useState(null);

  useEffect(() => {
    if (props.match) {
      setInfoSession(null);
      setInfoSession(getIdAppointment(props));
    }
  }, [props]);

  return (
    <Layout className='w-full'>
      <Content className='w-full h-auto'>
        {!infoSession ? <BrandLoading /> : <ContainerSala info={infoSession} />}
      </Content>
    </Layout>
  );
};

export default PacienteMD;
